import React, { useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as DownIcon } from "../../assets/icons/generic/downIcon.svg";
import { ReactComponent as ProjectsIcon } from "../../assets/icons/sidebar/DashboardIcon.svg";
import { useInstanceContext } from "../../context/useInstanceContext";

const ProjectsMenu = ({
  menuExpanded,
  expandProjectMenu,
  projectMenuExpanded,
}) => {
  const { selectedInstance } = useInstanceContext();

  const handleOpen = () => {
    expandProjectMenu();
  };

  return (
    <Container>
      {menuExpanded ? (
        <ProjectsBtn onClick={() => handleOpen(true)}>
          <span>
            {selectedInstance
              ? selectedInstance.production?.name
              : "Project Name"}
          </span>
          <DownIcon />
        </ProjectsBtn>
      ) : (
        <ItemContainer open={menuExpanded} onClick={() => handleOpen(true)}>
          <IconWrapper>
            <ProjectsIcon />
          </IconWrapper>
        </ItemContainer>
      )}
    </Container>
  );
};

export default ProjectsMenu;
const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 40px;
  height: 40px;
`;

const ProjectsBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures text stays left, icon stays right */
  min-width: 150px;
  width: 100%;
  gap: 10px;
  background: ${lightThemeColors.sidebar.projectsBg};
  padding: 11px 10px;
  border: 0px solid ${lightThemeColors.primaryColor};
  border-radius: 5px;
  font-size: 15px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: ${lightThemeColors.primaryColor};
  cursor: pointer;
  height: 40px;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden;
  text-overflow: ellipsis;

  &:disabled {
    opacity: 0.3;
  }

  span {
    flex-grow: 1; /* Allows text to take up space without affecting the icon */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    flex-shrink: 0; /* Prevents icon from shrinking */
    color: ${lightThemeColors.primaryColor};
    height: 16px;
    rotate: -90deg;
  }
`;
const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  background: ${(props) =>
    props.open
      ? props.selected
        ? lightThemeColors.sidebar.selectedItemBg
        : lightThemeColors.sidebar.elementExpandedBg
      : props.selected
      ? lightThemeColors.sidebar.selectedItemBg
      : ""};

  transition: 200ms linear;
  border-radius: 5px;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 30px;
  min-height: 46px;
  max-width: 30px;
  max-height: 46px;
  transition: 200ms linear;
  padding-left: 10px;
  background: ${(props) =>
    props.selected && !props.open
      ? lightThemeColors.sidebar.selectedItemBg
      : ""};
  margin-right: auto;
  border-radius: 5px;
  svg {
    position: relative;
    height: 16px;
    width: 16px;
    object-fit: contain;
    color: ${(props) =>
      props.selected
        ? lightThemeColors.primaryColor
        : lightThemeColors.darkColorFaded};
  }
`;
