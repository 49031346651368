import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import TestProdHeader from "../../components/Generic/TestProdHeader";
import { useOutletContext } from "react-router-dom";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";
import moment from "moment";

import AudiencePageContent from "./AudiencePageContent";
import AudienceModal from "../../components/audienceComponents/AudienceModal";
import { useVisitorContext } from "../../context/useVisitorsContext";
import { AnimatePresence, motion } from "framer-motion";
import { REFERRALS, TEST, USERS } from "../../constants/OptionsConstants";
import AudienceOptionsLine from "./AudienceOptionsline";
import AudienceOverview from "./AudienceOverview";

const AudiencePage = () => {
  const initialSort = {
    sortKey: "updated_at",
    ascendent: false,
  };

  const { projectType, setProjectType } = useOutletContext();
  const { selectedProject } = useInstanceContext();

  const {
    getVisitorsForSearchParams,
    getAgregatedVisitorsForSearchParams,
    visitorMetrics,
    visitorMetricsRef,
    setVisitorMetrics,

    agregatedVisitorMetrics,
    setAgregatedVisitorMetrics,
    agregatedVisitorMetricsRef,
  } = useVisitorContext();
  const { setLoading } = useOutletContext();

  const [searchInputValue, setSearchInputvalue] = useState("");
  const today = new Date();

  const initialDateStart = moment(today)
    .subtract(29, "day")
    .startOf("day")
    .utc();
  const initialDateEnd = moment(today).endOf("day").utc();

  const [startDate, setStartDate] = useState(new Date(initialDateStart));
  const [endDate, setEndDate] = useState(new Date(initialDateEnd));

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sort, setSort] = useState(initialSort);
  const [audienceType, setAudienceType] = useState(USERS);
  const [isAudienceModalOpen, setIsAudienceModalOpen] = useState(false);
  const [selectedAudienceInfo, setSelectedAudienceInfo] = useState(null);

  const handleGetAudience = () => {
    var dataObject = {
      ascendent: sort.ascendent,
      page: page,
      start_date: moment(startDate).toISOString(),
      sort_by: sort.sortKey,
    };

    if (searchInputValue != "") {
      dataObject.term = searchInputValue;
    }

    if (endDate) {
      dataObject.end_date = moment(endDate).toISOString();
    }

    setLoading(true);
    getVisitorsForSearchParams(
      selectedProject.id,
      dataObject,
      (response) => {
        visitorMetricsRef.current = response.data.metrics;
        setVisitorMetrics(visitorMetricsRef.current);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleGetReferralsAudience = () => {
    var dataObject = {
      ascendent: sort.ascendent,
      page: page,
      start_date: moment(startDate).toISOString(),
      sort_by: sort.sortKey,
    };

    if (searchInputValue != "") {
      dataObject.term = searchInputValue;
    }

    if (endDate) {
      dataObject.end_date = moment(endDate).toISOString();
    }

    setLoading(true);
    getAgregatedVisitorsForSearchParams(
      selectedProject.id,
      dataObject,
      (response) => {
        agregatedVisitorMetricsRef.current = response.data.metrics;
        setAgregatedVisitorMetrics(agregatedVisitorMetricsRef.current);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleOpenAudienceModal = (item) => {
    let found = visitorMetricsRef.current.find(
      (element) => element.id === item.id
    );
    setSelectedAudienceInfo(found);
    setIsAudienceModalOpen(true);
  };

  const handleSearchInputValue = (inputValue) => {
    setSearchInputvalue(inputValue);
  };

  useEffect(() => {
    if (!selectedProject) {
      return;
    }
    if (audienceType === USERS) {
      handleGetAudience();
    }
    if (audienceType === REFERRALS) {
      handleGetReferralsAudience();
    }
  }, [
    selectedProject,
    startDate,
    endDate,
    page,
    searchInputValue,
    sort,
    audienceType,
  ]);

  return (
    <Container>
      <TestProdHeader
        title={"Audience"}
        option={projectType}
        setOption={setProjectType}
      />

      <PageContent>
        <AudienceOptionsLine
          audienceType={audienceType}
          setAudienceType={setAudienceType}
        />
        <AudiencePageContent
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          totalPages={totalPages}
          setPage={setPage}
          page={page}
          setSort={setSort}
          setSearchInputvalue={setSearchInputvalue}
          handleSearchInputValue={handleSearchInputValue}
          searchInputValue={searchInputValue}
          sort={sort}
          visitorMetrics={visitorMetrics}
          agregatedVisitorMetrics={agregatedVisitorMetrics}
          handleOpenAudienceModal={handleOpenAudienceModal}
          audienceType={audienceType}
        />
        <AnimatePresence>
          {isAudienceModalOpen && (
            <AudienceModal
              isMoldaOpen={isAudienceModalOpen}
              setIsModalOpen={setIsAudienceModalOpen}
              selectedAudienceInfo={selectedAudienceInfo}
            />
          )}
        </AnimatePresence>
      </PageContent>
    </Container>
  );
};

export default AudiencePage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  h2 {
    width: 100%;
  }
  gap: 0px;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  padding-bottom: 30px;
  padding: 0 50px;
  position: relative;
  height: 100%;
  padding-top: 20px;
`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
`;

const AnimatedDiv = styled(motion.div)``;
