import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as Unchecked } from "../../assets/icons/generic/blankCheck.svg";
import { ReactComponent as Check } from "../../assets/icons/generic/validCircle.svg";

const LinkTypeElement = ({ title, subtitle, handleClick, icon, selected }) => {
  return (
    <LinkTypeElementContainer selected={selected} onClick={handleClick}>
      {icon}
      <TextWrapper>
        <h3>{title}</h3>
        <h4>{subtitle}</h4>
      </TextWrapper>
      {selected ? <Check color="rgba(52, 168, 83, 1)" /> : <Unchecked />}
    </LinkTypeElementContainer>
  );
};

export default LinkTypeElement;

const LinkTypeElementContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 20px;
  border-radius: 5px;
  background: ${lightThemeColors.linkTypeElementBg};
  border: ${(props) =>
    props.selected && `1px solid ${lightThemeColors.primaryColor}`};
  margin: ${(props) => props.selected && `-1px`};
  cursor: pointer;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  h3 {
    font-size: 17px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: ${lightThemeColors.darkColor};
  }
  h4 {
    margin-top: 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: ${lightThemeColors.darkColorFaded30};
  }
`;
