import {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
  useRef,
} from "react";

import {
  getProjectRedirectsAPICall,
  setDefaultRedirectAPICall,
  setRedirectAPICall,
} from "../api/configurations/redirect/configRedirectService";
import {
  getProjectDomainAPICall,
  setSubdomainAPICall,
  setGoogleTrackingIDAPICall,
} from "../api/configurations/domains/configDomainsService";

export const ConfigurationContext = createContext({
  projectRedirectsConfig: [],
  projectDomain: [],
  setProjectRedirectsConfig: () => {},
  setProjectDomain: () => {},
  getProjectRedirects: () => {},
  getProjectDomains: () => {},
  setProjectDefaultRedirect: () => {},
  setProjectRedirect: () => {},
  setSubdomain: () => {},
  setGoogleTrackingID: () => {},
});

const ConfigurationContextProvider = ({ children }) => {
  const [projectRedirectsConfig, setProjectRedirectsConfig] =
    useState(undefined);
  const [projectDomain, setProjectDomain] = useState(undefined);

  const getProjectRedirects = useCallback(
    async (projectId, onSuccess, onError) => {
      getProjectRedirectsAPICall(
        projectId,
        function (response) {
          setProjectRedirectsConfig(response.data.redirect_config);
          onSuccess(response);
        },
        onError
      );
    }
  );

  const getProjectDomains = useCallback(
    async (projectId, onSuccess, onError) => {
      getProjectDomainAPICall(
        projectId,
        function (response) {
          setProjectDomain(response.data.domain);
          onSuccess(response);
        },
        onError
      );
    }
  );

  const setSubdomain = useCallback(
    async (projectId, formData, onSuccess, onError) => {
      setSubdomainAPICall(
        projectId,
        formData,
        function (response) {
          setProjectDomain(response.data.domain);
          onSuccess(response);
        },
        onError
      );
    }
  );

  const setProjectDefaultRedirect = useCallback(
    async (projectId, default_fallback, onSuccess, onError) => {
      setDefaultRedirectAPICall(
        projectId,
        default_fallback,
        function (response) {
          onSuccess(response);
        },
        onError
      );
    }
  );

  const setProjectRedirect = useCallback(
    async (
      projectId,
      platform,
      variation,
      appstore,
      fallback_url,
      enabled,
      onSuccess,
      onError
    ) => {
      setRedirectAPICall(
        projectId,
        platform,
        variation,
        appstore,
        fallback_url,
        enabled,
        function (response) {
          onSuccess(response);
        },
        onError
      );
    }
  );

  const setGoogleTrackingID = useCallback(
    async (projectId, formData, onSuccess, onError) => {
      setGoogleTrackingIDAPICall(
        projectId,
        formData,
        function (response) {
          setProjectDomain(response.data.domain);
          onSuccess(response);
        },
        onError
      );
    }
  );

  return (
    <ConfigurationContext.Provider
      value={{
        getProjectRedirects,
        getProjectDomains,
        projectRedirectsConfig,
        projectDomain,
        setProjectDefaultRedirect,
        setProjectDomain,
        setProjectRedirectsConfig,
        setSubdomain,
        setProjectRedirect,
        setGoogleTrackingID,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};

export const useConfigurationContext = () => useContext(ConfigurationContext);

export default ConfigurationContextProvider;
