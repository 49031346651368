import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../../components/configurationComponents/ConfigurationSectionHeader";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import LableWithArrow from "../../components/configurationComponents/LableWithArrow";
import FacebookCardPreview from "../../components/configurationComponents/FacebookCardPreview";
import SocialMediaButtonsGroup from "../../components/configurationComponents/SocialMediaButtonsGroup";
import {
  FACEBOOK,
  FILE,
  LINK,
  LINKEDIN,
  X,
} from "../../constants/OptionsConstants";
import { ReactComponent as FileIcon } from "../../assets/icons/generic/fileIcon.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/generic/removeIcon.svg";
import { ReactComponent as DetailsIcon } from "../../assets/icons/generic/socialMediaPreviewGroupIcon.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg";
import { useConfigurationContext } from "../../context/useConfigurationContext";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";
import { ReactComponent as SocialMediaConfigIcon } from "../../assets/icons/generic/socialMediaPreviewIcon.svg";
import SubcategoryTitleWrapper from "../../components/configurationComponents/SubcategoryTitleWrapper";
import OptionButton from "../../components/Generic/OptionButton";
import SecondaryButton from "../../components/Generic/SecondaryButton";

import { useOutletContext, useSearchParams } from "react-router-dom";
import XCardPreview from "../../components/configurationComponents/XCardPreview";
import LinkedInCardPreview from "../../components/configurationComponents/LinkedInCardPreview";
import { isURL } from "validator";

const SocialMediaConfig = () => {
  const { projectDomain, setSubdomain } = useConfigurationContext();
  const { selectedProject } = useInstanceContext();
  const { setLoading } = useOutletContext();
  const [searchParams, setSearchParams] = useSearchParams();


  const socialMediaButtonsList = [
    {
      value: LINK,
      text: "Link",
    },
    {
      value: FILE,
      text: "Upload",
    },
  ];

  const [expanded, setExpanded] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [socialMediaCardType, setSocialMediaCardType] = useState(FACEBOOK);
  const [imageType, setImageType] = useState(LINK);
  const [imageFile, setImageFile] = useState();
  const [imageLink, setImageLink] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [replace, setReplace] = useState(false)

  const inputFileRef = useRef();

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const handleInputFile = () => {
    inputFileRef.current.click();
  };

  const handleSetImage = (e) => {
    setImageFile(e.currentTarget.files[0]);
    let preview = createPreview(e.currentTarget.files[0]);
    setImagePreview(preview);
  };

  const handleRemoveImageFile = () => {
    inputFileRef.current.value = null;
    setImageFile(null);
    setImagePreview(null);
  };

  const handleSetSubdomain = () => {
    setLoading(true);
    let formData = new FormData();
    formData.append("generic_title", title);
    formData.append("generic_subtitle", subTitle);

    if (imageType === LINK) {
      if (isURL(imageLink)) {
        formData.append("generic_image_url", imageLink);
      }
    } else {
      formData.append("generic_image", imageFile);
    }

    setSubdomain(
      selectedProject.id,
      formData,
      (response) => {
        setLoading(false);
        setReplace(false)
        setImageLink("")
        setImageFile()
        if (inputFileRef.current) { inputFileRef.current.value = null; }
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleSaveButtonClick = () => {
    handleSetSubdomain();
  };

  const handleSetReplace = () => {
    setReplace(true)
    setImageLink("");
    setImageFile(null)
  }


  const createPreview = (imageInput) => {
    if (!imageInput) {
      return;
    }
    let preview = URL.createObjectURL(imageInput);
    return preview;
  };

  const checkChanges = () => {
    if (!projectDomain) {
      return;
    }

    let titleChanged = false;
    let subtitleChanged = false;
    let linkChanged = false;

    if (projectDomain.generic_title) {
      if (title !== projectDomain.generic_title) {
        titleChanged = true
      }
    } else if (title !== "") {
      titleChanged = true
    }

    if (projectDomain.generic_subtitle) {
      if (subTitle !== projectDomain.generic_subtitle) {
        subtitleChanged = true
      }
    } else if (subTitle !== "") {
      subtitleChanged = true
    }
    if (projectDomain.generic_image_url) {
      if (imageLink !== "" && isURL(imageLink)) {
        linkChanged = true
      }
    } else if (imageLink !== "" && isURL(imageLink)) {
      linkChanged = true
    }

    if (imageType === LINK) {

      if (
        titleChanged ||
        subtitleChanged ||
        linkChanged
      ) {
        setSaveDisabled(false);
      } else {
        setSaveDisabled(true);
      }
    } else {
      if (
        title !== projectDomain.generic_title ||
        subTitle !== projectDomain.generic_subtitle ||
        imageFile
      ) {
        setSaveDisabled(false);
      } else {
        setSaveDisabled(true);
      }
    }
  };

  const handleSelectImageType = (imageType) => {
    setImageType(imageType);
  };

  const displaySocialMediaCard = () => {
    var displayCard = <></>;
    switch (socialMediaCardType) {
      case FACEBOOK:
        displayCard = (
          <FacebookCardPreview
            img={imagePreview}
            title={title}
            domain={
              "http://" + projectDomain.subdomain + "." + projectDomain.domain
            }
            subtitle={subTitle}
          />
        );
        break;
      case X:
        displayCard = (
          <XCardPreview
            img={imagePreview}
            title={title}
            domain={
              "http://" + projectDomain.subdomain + "." + projectDomain.domain
            }
            subtitle={subTitle}
          />
        );

        break;
      case LINKEDIN:
        displayCard = (
          <LinkedInCardPreview
            img={imagePreview}
            title={title}
            domain={
              "http://" + projectDomain.subdomain + "." + projectDomain.domain
            }
            subtitle={subTitle}
          />
        );
        break;

      default:
        break;
    }
    return displayCard;
  };


  const expandIfTour = () => {
    if (searchParams.get("socialMediaTour") === "true") {
      setExpanded(true);
      setSearchParams((params) => {
        params.delete("socialMediaTour")
        return params
      })
    }
  }

  useEffect(() => {
    expandIfTour();
  }, [searchParams])

  useEffect(() => {
    if (!projectDomain) {
      return;
    }
    setSubTitle(projectDomain.generic_subtitle || "");
    setTitle(projectDomain.generic_title || "");
    setImagePreview(projectDomain.generic_image_url);

  }, [projectDomain]);

  useEffect(() => {
    if (imageType === LINK) {
      if (imageLink != "") {
        setImagePreview(imageLink);
      }
    } else {
      if (imageFile) {
        let preview = createPreview(imageFile);
        setImagePreview(preview);
      }
    }
  }, [imageType, imageLink, imageFile]);

  useEffect(() => {
    checkChanges();
  }, [title, subTitle, imagePreview, imageLink, projectDomain, imageType]);

  return (
    <Container id={"socialMediaPreviewContainer"}>
      <ConfigurationSectionHeader
        title={"Social Media Preview"}
        handleExpand={handleExpand}
        expanded={expanded}
        showValidation
        isValid={
          projectDomain?.generic_title && projectDomain?.generic_subtitle
        }
        unsavedChanges={saveDisabled === false}
        customButton={
          saveDisabled === false && (
            <PrimaryButton
              text={"Save"}
              bgVariant={lightThemeColors.background}
              styled={{ color: lightThemeColors.primaryColor }}
              onClick={() => handleSaveButtonClick()}
              disabled={saveDisabled}
            />
          )
        }
        icon={<SocialMediaConfigIcon />}
      />

      {expanded && (
        <ContentContainer>
          <SubcategoryContent>
            <SubcategoryTitleWrapper
              icon={<DetailsIcon />}
              text="Details"
              id={"DetailsConfigId"}
              colorType={"faded-dark"}
            />

            <ColumnGroup>
              <Line>
                <Title>Image</Title>
              </Line>
              {(!projectDomain.generic_image_url ||
                replace)

                &&
                <>
                  <Line style={{ gap: "10px" }}>
                    {socialMediaButtonsList?.map((item, index) => (
                      <OptionButton
                        key={index}
                        text={item.text}
                        selected={imageType === item.value}
                        onClick={() => handleSelectImageType(item.value)}
                      />
                    ))}
                  </Line>
                  <>
                    {imageType === FILE && (
                      <UploadSectionWrapper>
                        <InfoText>
                          Add the link to an image for social media previewthis
                          image will be displayed as a thumbnail when you share the
                          link on a social platform or chat.
                        </InfoText>
                        <Line>
                          <SecondaryButton
                            text={imageFile ? "Replace" : "Add image"}
                            icon={<PlusIcon />}
                            onClick={() => handleInputFile()}
                          />
                        </Line>
                        <input
                          type="file"
                          accept=".jpg,.jpeg,.png"
                          ref={inputFileRef}
                          style={{ display: "none" }}
                          onChange={(e) => handleSetImage(e)}
                        />
                      </UploadSectionWrapper>
                    )}
                  </>
                  <>
                    {imageType === LINK && (
                      <InputFieldWithValidation
                        subtitle={
                          "Add the link to an image for social media preview; this image will be displayed as a thumbnail when you share the link on a social platform or chat."
                        }
                        placeholder={"Image URL"}
                        inputValue={imageLink}
                        valid={imageLink && isURL(imageLink)}
                        handleInputChange={(e) =>
                          setImageLink(e.currentTarget.value)
                        }
                      />
                    )}
                  </>
                </>
              }
              {(projectDomain.generic_image_url && !replace) &&
                <Line>
                  <SecondaryButton
                    text={"Replace"}
                    icon={<PlusIcon />}
                    onClick={() => handleSetReplace(true)}
                  />
                </Line>
              }
            </ColumnGroup>

            <InputFieldWithValidation
              title={"Title"}
              noValidation
              placeholder={"Enter the default title for your links..."}
              inputValue={title}
              handleInputChange={(e) => setTitle(e.currentTarget.value)}
            />
            <InputFieldWithValidation
              title={"Subtitle"}
              placeholder={"Enter the default subtitle for your links..."}
              noValidation
              inputValue={subTitle}
              handleInputChange={(e) => setSubTitle(e.currentTarget.value)}
            />
          </SubcategoryContent>

          <SubcategoryContent>
            <ColumnGroup>
              <Line>
                <Title>Social Media Preview</Title>
              </Line>
              <LableWithArrow
                text={"Here's a preview of your social media appearance."}
                arrowDirection={"down"}
                reverse
              />

              <SocialMediaButtonsGroup
                selectedOption={socialMediaCardType}
                setSelectedOption={setSocialMediaCardType}
              />
              {displaySocialMediaCard()}
            </ColumnGroup>
          </SubcategoryContent>
        </ContentContainer>

      )}
    </Container>
  );
};

export default SocialMediaConfig;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 30px;
`;

const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  padding-bottom: 30px;
  row-gap: 50px;
`;
const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 40px;
  width: 100%;
  max-width: 620px;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  p {
    font-size: 16px;
    font-weight: 500;
    color: ${lightThemeColors.darkColor};
    line-height: 24px;
  }
`;

const SeparatorNoMargin = styled.div`
  display: flex;
  height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
`;
const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const Title = styled.h1`
  color: ${lightThemeColors.darkColorFaded};
  font-size: 15px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
`;

const UploadSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }
  h2 {
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;

const ImageInputSpan = styled.div`
  display: flex;
  padding: 9px 10px;
  border-radius: 5px;
  align-items: center;
  gap: 10px;
  border: 1px solid ${lightThemeColors.darkColorFullFaded};
  min-height: 40px;
  width: 100%;
  span {
    font-size: 16px;
    font-weight: 500;
    color: ${lightThemeColors.darkColorFaded60};
    line-height: 20px;
  }
`;

const RemoveFileButton = styled.button`
  border: none;
  cursor: pointer;
  background: none;
  margin-left: auto;
`;
const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
