import React from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'
import { ReactComponent as EmptyIcon } from "../../assets/icons/generic/campaignsEmptyStateIcon.svg"
import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg"
import PrimaryButton from '../../components/Generic/PrimaryButton'
import { useOutletContext } from 'react-router-dom'

const CampaignsLinksEmptyState = ({ campaign }) => {
    const { setAddNewLinkForCampaignID } = useOutletContext()
    return (
        <Container>
            <EmptyIcon />
            <InfoText>Your campaign has no links yet. Create links for each platform you want to advertise on and start tracking installs, views, opens, and revenue.</InfoText>
            <PrimaryButton
                icon={<PlusIcon />}
                text={"Add the first link to the campaign"}
                onClick={() => {
                    setAddNewLinkForCampaignID(campaign.id);
                }}
            />
        </Container>
    )
}

export default CampaignsLinksEmptyState

const Container = styled.div`
display:flex;
align-items:center;
justify-content:center;
flex-direction:column;
gap:20px;
`
const InfoText = styled.h3`
font-weight: 400;
font-size: 14px;
line-height: 150%;
text-align: center;
 color: ${lightThemeColors.darkColorFaded30};
 max-width:400px;
`