import React from "react";
import { lightThemeColors } from "../../assets/styles/colors";
import styled from "styled-components";

import { copyToClipboard } from "../../helper/copy";
import { useInstanceContext } from "../../context/useInstanceContext";
import AndroidIntegrationText from "./AndroidIntegrationText";
import ReactNativeIntegrationText from "./ReactNativeIntegrationText";

const ReactNativeAndroidIntegrationText = ({ ref, onMouseMove }) => {
  const { selectedInstance } = useInstanceContext();

  const indent = "        ";
  return (
    <Container>
      <AndroidIntegrationText ref={ref} onMouseMove={onMouseMove} />
      <ReactNativeIntegrationText ref={ref} onMouseMove={onMouseMove} />
    </Container>
  );
};

export default ReactNativeAndroidIntegrationText;

const TextCodeContainer = styled.pre`
  display: flex;
  flex-direction: column;
  border: 1px solid ${lightThemeColors.menuBorder};
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  overflow: hidden;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${lightThemeColors.darkColorFaded60};
  h2 {
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${lightThemeColors.darkColorFaded60};
  }
  span {
    &:hover {
      background: ${lightThemeColors.sidebar.linkBg};
    }
    white-space: pre-wrap;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-weight: 600;
    color: ${lightThemeColors.primaryColor};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 20px;
`;

const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 40px;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
  margin: 30px 0;
`;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  max-width: 635px;
  h3 {
    margin-top: 10px;
    color: ${lightThemeColors.darkColorFaded};
    font-size: 15px;
  }
`;
