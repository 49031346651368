import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../configurationComponents/ConfigurationSectionHeader";
import { useExpanded } from "../../hooks/useExpanded";
import AudienceDisplayInfoFields from "./AudienceDisplayInfoFields";
import moment from "moment";
import AudienceInvited from "./AudienceInvited";
import { parseSecondsInHoursMinutesSeconds } from "../../helper/Utils";

const AudienceAnalytics = ({
  selectedAudienceInfo,
  agregatedMetrics,
  metrics,
}) => {
  const [expanded, expand] = useExpanded(false);

  return (
    <Container>
      <ConfigurationSectionHeader
        title={"Analytics"}
        handleExpand={expand}
        expanded={expanded}
      />
      {expanded && (
        <ContentContainer>
          <ColumnGroup>
            <SubcategoryTitle>User Analytics</SubcategoryTitle>
            <Subcategory>
              <AudienceDisplayInfoFields
                title={"Platform"}
                value={metrics?.platform}
                floatingInfo={"The platform of the users's device."}
              />
              <AudienceDisplayInfoFields
                title={"Opens"}
                value={metrics?.open_count}
                floatingInfo={
                  "This represents the number of app opens that occurred from a grovs link."
                }
              />
              <AudienceDisplayInfoFields
                title={"Installs"}
                value={metrics?.install_count}
                floatingInfo={
                  "This represents the number of app installations."
                }
              />
              <AudienceDisplayInfoFields
                title={"Reinstalls"}
                value={metrics?.reinstall_count}
                floatingInfo={
                  "The number of app reinstalls. A reinstall occurs when the app is deleted and reinstalled on the same device."
                }
              />
              <AudienceDisplayInfoFields
                title={"Engagement time"}
                value={parseSecondsInHoursMinutesSeconds(
                  metrics?.time_spent_engagement_time
                )}
                floatingInfo={"Time spent in the app after clicking the link."}
              />
            </Subcategory>
          </ColumnGroup>

          <ColumnGroup>
            <SubcategoryTitle>Referral Metrics</SubcategoryTitle>
            <SubcategorySubTitle>
              These values represent the combined metrics from all users and
              links referred by the user.
            </SubcategorySubTitle>
            <Subcategory>
              <AudienceDisplayInfoFields
                title={"Generated links"}
                value={agregatedMetrics?.number_of_generated_links}
                floatingInfo={"The number of links generated by the user."}
              />
              <AudienceDisplayInfoFields
                title={"Installs attributed"}
                value={agregatedMetrics?.install_count}
                floatingInfo={
                  "The number of installs attributed to the user through their shared links."
                }
              />
              <AudienceDisplayInfoFields
                title={"Reinstalls attributed"}
                value={agregatedMetrics?.reinstall_count}
                floatingInfo={
                  "The number of reinstalls attributed to the user through their shared links."
                }
              />
              <AudienceDisplayInfoFields
                title={"Engagement time attributed"}
                value={parseSecondsInHoursMinutesSeconds(
                  agregatedMetrics?.time_spent_engagement_time
                )}
                floatingInfo={
                  "The sum of engagment time attributed to the user through their shared links."
                }
              />
              <AudienceDisplayInfoFields
                title={"User referrenced"}
                value={agregatedMetrics?.user_referred_count}
                floatingInfo={
                  "The number of new users attributed to the user through their shared links."
                }
              />
            </Subcategory>
          </ColumnGroup>
          <ColumnGroup>
            <SubcategoryTitle>Dates</SubcategoryTitle>
            <Subcategory>
              <AudienceDisplayInfoFields
                title={"First access date"}
                value={moment(selectedAudienceInfo.created_at).format(
                  "DD MMM, YYYY - hh:mm:ss"
                )}
                floatingInfo={
                  "Date when the user was first detected by the grovs SDK."
                }
              />
              <AudienceDisplayInfoFields
                title={"Last access date"}
                value={moment(selectedAudienceInfo.updated_at).format(
                  "DD MMM, YYYY - hh:mm:ss"
                )}
                floatingInfo={
                  "Date when the user was last detected by the grovs SDK."
                }
              />
            </Subcategory>
          </ColumnGroup>

          {selectedAudienceInfo.invited.length > 0 && (
            <ColumnGroup>
              <SubcategoryTitle>Invited</SubcategoryTitle>
              <Subcategory>
                <AudienceInvited members={selectedAudienceInfo.invited} />
              </Subcategory>
            </ColumnGroup>
          )}
        </ContentContainer>
      )}
    </Container>
  );
};

export default AudienceAnalytics;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 30px;
`;
const ContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  row-gap: 50px;
  padding: 0 0px;
`;

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
`;

const Subcategory = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;

const SubcategoryTitle = styled.h1`
  color: ${lightThemeColors.darkColorFaded};
  font-size: 16px;
  font-weight: 700;
  line-height: 27px;
  margin-bottom: 5px;
`;

const SubcategorySubTitle = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;
