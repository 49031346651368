import React from "react";
import styled from "styled-components";
import {
  GOOGLE,
  GROVS,
  LINKEDIN,
  META,
  QUICK_LINK,
  TIKTOK,
} from "../../constants/OptionsConstants";
import { ReactComponent as GoogleAdIcon } from "../../assets/icons/linkTypeAdsIcons/Google.svg";
import { ReactComponent as MetaAdIcon } from "../../assets/icons/linkTypeAdsIcons/MetaPlatforms.svg";
import { ReactComponent as LinkedinAdIcon } from "../../assets/icons/linkTypeAdsIcons/LinkedIn.svg";
import { ReactComponent as TikTokAdIcon } from "../../assets/icons/linkTypeAdsIcons/TikTok.svg";
import { ReactComponent as QuickLink } from "../../assets/icons/linkTypeAdsIcons/Grovs.svg";

const AdsPlatformCell = ({ platform }) => {
  return (
    <Container>
      {platform === GOOGLE && <GoogleAdIcon />}
      {platform === META && <MetaAdIcon />}
      {platform === LINKEDIN && <LinkedinAdIcon />}
      {platform === TIKTOK && <TikTokAdIcon />}
      {platform === QUICK_LINK && <QuickLink />}
      {platform == null && <p>-</p>}
    </Container>
  );
};

export default AdsPlatformCell;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  svg {
    max-height: 25px;
  }
`;
