import React from "react";
import { lightThemeColors } from "../../assets/styles/colors";
import styled from "styled-components";

import { copyToClipboard } from "../../helper/copy";
import { useInstanceContext } from "../../context/useInstanceContext";

const AppDelegateText = ({ ref, onMouseMove }) => {
  const { selectedInstance } = useInstanceContext();

  const indent = "        ";
  return (
    <TextCodeContainer ref={ref} onMouseMove={onMouseMove} id="iosSDKText">
      <h2>import UIKit</h2>

      <span
        data-tooltip-id={"iosSDK-tooltip"}
        data-tooltip-content={"Click text to copy"}
        onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
      >{`import Grovs`}</span>
      <br />
      <h2>@main</h2>
      <h2>{`class AppDelegate: UIResponder, UIApplicationDelegate {`}</h2>
      <br />
      <h2>{`  func application(_ application: UIApplication, didFinishLaunchingWithOptions
                     launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {

        // Configure the SDK`}</h2>

      <span
        data-tooltip-id={"iosSDK-tooltip"}
        data-tooltip-content={"Click text to copy"}
        onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
      >
        {indent +
          `Grovs.configure(APIKey: "${selectedInstance.api_key}", useTestEnvironment: false, delegate: self)`}
      </span>
      <br />
      <h2>{"        "}// Other code</h2>
      <br />
      <h2> {"        "}return true</h2>
      <h2>{`  }`}</h2>
      <br />
      <h2>
        {" "}
        {`func application(_ app: UIApplication, open url: URL, options: [UIApplication.OpenURLOptionsKey: Any] = [:]) -> Bool {
        
        // Handle URLs`}
      </h2>

      <h2>
        {"        "}
        return{" "}
        <span
          data-tooltip-id={"iosSDK-tooltip"}
          data-tooltip-content={"Click text to copy"}
          onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
        >
          Grovs.handleAppDelegate(open: url, options: options)
        </span>
      </h2>
      <h2>{`  }`}</h2>
      <br />
      <h2>{`  func application(_ application: UIApplication, continue userActivity: NSUserActivity, restorationHandler: @escaping ([UIUserActivityRestoring]?) -> Void) -> Bool {

        // Handle URLs`}</h2>
      <h2>
        {"        "}
        return{" "}
        <span
          data-tooltip-id={"iosSDK-tooltip"}
          data-tooltip-content={"Click text to copy"}
          onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
        >
          Grovs.handleAppDelegate(continue: userActivity, restorationHandler:
          restorationHandler)
        </span>
      </h2>
      <h2>{`  }`}</h2>
      <br />
      <h2>{`}`}</h2>
      <br />
      <h2>{`extension AppDelegate: GrovsDelegate {`}</h2>
      <span
        data-tooltip-id={"iosSDK-tooltip"}
        data-tooltip-content={"Click text to copy"}
        onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
      >
        {indent +
          `func grovsReceivedPayloadFromDeeplink(link: String?, payload: [String: Any]?) {
                   print("Received payload:")
                   debugPrint(payload)
    }`}
      </span>
      <h2>{`}`}</h2>
    </TextCodeContainer>
  );
};

export default AppDelegateText;

const TextCodeContainer = styled.pre`
  display: flex;
  flex-direction: column;
  border: 1px solid ${lightThemeColors.menuBorder};
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  overflow: hidden;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${lightThemeColors.darkColorFaded60};
  h2 {
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${lightThemeColors.darkColorFaded60};
  }
  span {
    &:hover {
      background: ${lightThemeColors.sidebar.linkBg};
    }
    white-space: pre-wrap;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-weight: 600;
    color: ${lightThemeColors.primaryColor};
  }
`;
