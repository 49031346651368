import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'
import PageHeader from '../../components/Generic/PageHeader'
import InputFieldWithValidation from '../../components/Generic/InputFieldWithValidation'
import SecondaryButton from '../../components/Generic/SecondaryButton'
import { ReactComponent as LogoutIcon } from "../../assets/icons/generic/LogoutIcon.svg"
import { useUserContext } from '../../context/useUserContext'
import LocalStorage from '../../helper/LocalStorage'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { showGenericError, showSuccessNotification } from '../../helper/Notifications'
import TwoFAModal from './TwoFAModal'
import { AnimatePresence } from 'framer-motion'
import DeleteConfirmationChild from '../../components/Generic/DeleteConfirmationWithChild'
import { REMOVE_ACCOUNT } from '../../constants/OptionsConstants'
import RemoveAccountInfoModal from './RemoveAccountInfoModal'

const AccountPage = ({ setIsLoading }) => {

    const { logoutUser, removeAccount, editUser } = useUserContext();
    const { currentUser } = useUserContext();
    const { setLoading } = useOutletContext();

    const user = currentUser();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [show2FAModal, setShow2FAModal] = useState(false)
    const [optRequired, setOtpRequired] = useState(false)
    const [removeAccountConfirmation, setRemoveAccountConfirmation] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [nameChanged, setNameChanged] = useState(false)

    const navigate = useNavigate();

    const handleSignOut = () => {
        logoutUser(
            LocalStorage.getAuthenticationToken(),
            (response) => {
                navigate("/login")
            },
            (error) => {
                showGenericError()
            }
        )
    }

    const handleRemoveAccount = () => {
        setLoading(true)
        removeAccount(
            user.email,
            (response) => {
                setLoading(false)
                navigate("/login")
            },
            (error) => {
                if (error.response.status === 412) {
                    setShowInfoModal(true)
                }
                console.log(error)
                setLoading(false)
            }
        )
    }

    const handleEditUser = () => {
        setLoading(true)
        const objData = {
            name: name
        }
        editUser(
            objData,
            (response) => {
                setLoading(false)
            },
            (error) => {
                console.log(error)
                setLoading(false)
            }
        )
    }

    const handle2FABtn = () => {
        setShow2FAModal(true)
    }


    const setInitialState = () => {
        setName(user.name);
        setEmail(user.email);
        setOtpRequired(user.otp_required_for_login)
    }

    useEffect(() => {
        if (!user) {
            return
        }
        setInitialState();
    }, [user])

    useEffect(() => {
        const checkNameChanges = () => {
            if (user.name != name) {
                setNameChanged(true)
            } else {
                setNameChanged(false)
            }
        }
        checkNameChanges()
    }, [name, user])

    return (
        <Container>
            <AnimatePresence>
                {show2FAModal &&
                    <TwoFAModal
                        setIsModalOpen={setShow2FAModal}
                    />
                }
                {showInfoModal &&
                    <RemoveAccountInfoModal
                        setIsModalOpen={setShowInfoModal}
                    />
                }
            </AnimatePresence>

            <PageHeader />
            <TitleWrapper>
                <h1>Account</h1>
            </TitleWrapper>

            <Content>
                <InfoLine >
                    <InputFieldWithValidation
                        style={{ maxWidth: "400px" }}
                        title={"Full Name"}
                        noValidation
                        inputValue={name}
                        handleInputChange={(e) => setName(e.currentTarget.value)}
                    />
                    <InputFieldWithValidation
                        style={{ maxWidth: "400px" }}
                        title={"E-mail"}
                        inputValue={email}
                        handleInputChange={(e) => setEmail(e.currentTarget.value)}
                        noValidation
                        readOnly
                    />
                    <SecondaryButton
                        style={{ minWidth: "170px" }}
                        text={"Change Password"}
                        onClick={() => navigate("/reset_password")}
                    />
                    {optRequired ?
                        <SecondaryButton
                            style={{ minWidth: "170px" }}
                            text={"Disable 2FA"}
                            variant={lightThemeColors.authentication.inputErrorColor}
                            onClick={() => handle2FABtn()}
                        />
                        :
                        <SecondaryButton
                            style={{ minWidth: "170px" }}
                            text={"Enable 2FA"}
                            onClick={() => handle2FABtn()}
                        />
                    }
                    {nameChanged &&
                        <SecondaryButton
                            style={{ minWidth: "170px" }}
                            text={"Change name"}
                            onClick={() => handleEditUser()}
                        />

                    }

                </InfoLine>
                <Separator />
                <Line style={{ marginLeft: "auto" }}>
                    <DeleteConfirmationChild
                        open={removeAccountConfirmation}
                        setOpen={setRemoveAccountConfirmation}
                        action={handleRemoveAccount}
                        variant={REMOVE_ACCOUNT}
                    >
                        <SecondaryButton
                            variant={lightThemeColors.darkColorFaded30}
                            text={"Remove Account"}
                            onClick={() => setRemoveAccountConfirmation(true)}
                        />
                    </DeleteConfirmationChild>
                    <SecondaryButton
                        icon={<LogoutIcon />}
                        variant={lightThemeColors.authentication.inputErrorColor}
                        text={"Log Out"}
                        onClick={() => handleSignOut()}
                    />

                </Line>
            </Content>

        </Container>
    )
}

export default AccountPage

const Container = styled.div`
display:flex;
flex-direction:column;
height:100%;
width:100%;

h2{
    width:100%;
}
gap:20px;
`

const TitleWrapper = styled.div`
padding:0 50px;
h1{
    font-size: 36px;
    font-weight: 700;
    color:${lightThemeColors.darkColor};
}


`
const Content = styled.div`
display:flex;
flex-direction:column;
gap:50px;
overflow:auto;
padding-bottom:30px;
padding:0 50px;
position:relative;
`
const Line = styled.div`
display:flex;
align-items:center;
gap:20px;
`
const InfoLine = styled(Line)`
align-items:end;
`
const Separator = styled.div`
height:1px;
min-height:1px;
width:100%;
background:${lightThemeColors.darkColorFullFaded};

`