import React from "react";
import { lightThemeColors } from "../../assets/styles/colors";
import styled from "styled-components";

import { copyToClipboard } from "../../helper/copy";
import { useInstanceContext } from "../../context/useInstanceContext";

import ReactNativeIntegrationText from "./ReactNativeIntegrationText";

const ReactNativeiOSText = ({ ref, onMouseMove }) => {
  const { selectedInstance } = useInstanceContext();

  const indent = "        ";
  return (
    <Container>
      <TextCodeContainer ref={ref} onMouseMove={onMouseMove} id="iosSDKText">
        <h2>import UIKit</h2>

        <span
          data-tooltip-id={"iosSDK-tooltip"}
          data-tooltip-content={"Click text to copy"}
          onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
        >{`import Grovs`}</span>
        <br />
        <h2>@main</h2>
        <h2>{`class AppDelegate: UIResponder, UIApplicationDelegate {`}</h2>
        <br />
        <h2>{`  func application(_ application: UIApplication, didFinishLaunchingWithOptions
                       launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
  
          // Configure the SDK`}</h2>

        <span
          data-tooltip-id={"iosSDK-tooltip"}
          data-tooltip-content={"Click text to copy"}
          onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
        >
          {indent +
            `Grovs.configure(APIKey: "${selectedInstance.api_key}", useTestEnvironment: false, delegate: self)`}
        </span>
        <br />
        <h2>{"        "}// Other code</h2>
        <br />
        <h2> {"        "}return true</h2>
        <h2>{`  }`}</h2>
        <br />
        <h2>
          {" "}
          {`func application(_ app: UIApplication, open url: URL, options: [UIApplication.OpenURLOptionsKey: Any] = [:]) -> Bool {
          
          // Handle URLs`}
        </h2>

        <h2>
          {"        "}
          return{" "}
          <span
            data-tooltip-id={"iosSDK-tooltip"}
            data-tooltip-content={"Click text to copy"}
            onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
          >
            Grovs.handleAppDelegate(open: url, options: options)
          </span>
        </h2>
        <h2>{`  }`}</h2>
        <br />
        <h2>{`  func application(_ application: UIApplication, continue userActivity: NSUserActivity, restorationHandler: @escaping ([UIUserActivityRestoring]?) -> Void) -> Bool {
  
          // Handle URLs`}</h2>
        <h2>
          {"        "}
          return{" "}
          <span
            data-tooltip-id={"iosSDK-tooltip"}
            data-tooltip-content={"Click text to copy"}
            onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
          >
            Grovs.handleAppDelegate(continue: userActivity, restorationHandler:
            restorationHandler)
          </span>
        </h2>
        <h2>{`  }`}</h2>
        <br />
        <h2>{`}`}</h2>
        <br />
      </TextCodeContainer>
      <ReactNativeIntegrationText ref={ref} onMouseMove={onMouseMove} />
    </Container>
  );
};

export default ReactNativeiOSText;

const TextCodeContainer = styled.pre`
  display: flex;
  flex-direction: column;
  border: 1px solid ${lightThemeColors.menuBorder};
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  overflow: hidden;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${lightThemeColors.darkColorFaded60};
  h2 {
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${lightThemeColors.darkColorFaded60};
  }
  span {
    &:hover {
      background: ${lightThemeColors.sidebar.linkBg};
    }
    white-space: pre-wrap;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-weight: 600;
    color: ${lightThemeColors.primaryColor};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 20px;
`;

const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 40px;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
  margin: 30px 0;
`;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  max-width: 635px;
  h3 {
    margin-top: 10px;
    color: ${lightThemeColors.darkColorFaded};
    font-size: 15px;
  }
`;
