import { useEffect, useRef, useState } from "react";
import LoaderComponent from "./components/Generic/LoaderComponent";
import SideBarMenu from "./components/SideNav/SideBarMenu";
import {
  Outlet,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
import {
  ANDROID,
  DESKTOP,
  IOS,
  PRODUCTION,
  TEST,
  WEB,
} from "./constants/OptionsConstants";
import CreateNewLinkModal from "./components/createNewLink/CreateNewLinkModal";
import CreateNewProjectModal from "./components/createNewProject/CreateNewProjectModal";
import { useInstanceContext } from "./context/useInstanceContext";
import { showGenericError } from "./helper/Notifications";
import CreateFirstProjectModal from "./components/createNewProject/CreateFirstProjectModal";
import { lastIndexOf } from "lodash";
import TestModeBanner from "./components/Generic/TestModeBanner";
import { AnimatePresence, motion } from "framer-motion";
import { useConfigurationContext } from "./context/useConfigurationContext";
import NoSdkBanner from "./components/Generic/NoSdkBanner";
import { usePaymentsContext } from "./context/usePaymentsContext";
import NoSubscriptionBanner from "./components/Generic/NoSubscriptionBanner";
import ContactSaleModal from "./components/subscriptionComponents/ContactSaleModal";
import { driver } from "driver.js";

import "driver.js/dist/driver.css";
import { scrollToElement, waitForElement } from "./helper/tourHelper";
import Joyride from "react-joyride";
import { ChatwootProvider } from "./context/useChatwoot";

function App() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [projectType, setProjectType] = useState(PRODUCTION);
  const [addNewLinkModalOpen, setAddNewLinkModalOpen] = useState(false);
  const [addNewLinkForCampaignID, setAddNewLinkForCampaignID] = useState(null);
  const [createNewProjectModalOpen, setCreateNewProjectModalOpen] =
    useState(false);
  const [contactSaleModalOpen, setContactSaleModalOpen] = useState(false);
  const firstLoginRef = useRef(false);

  const {
    instances,
    selectedProject,
    getInstances,
    setSelectedProject,
    selectedInstance,
    setSelectedInstance,
    getInstanceUserRole,
    getProjectConfiguration,
  } = useInstanceContext();

  const { getSubscriptionDetails, getCurrentMau } = usePaymentsContext();
  // const [run, setRun] = useState(true);

  const navigate = useNavigate();

  const initializeFromParams = () => {
    let projectType = searchParams.get("env_type");
    if (projectType) {
      setProjectType(projectType);
    }
  };

  const handleGetInstances = () => {
    setLoading(true);
    getInstances(
      (response) => {
        const instances = response.data.instances;
        let sortedInstances = instances.sort((a, b) =>
          a.updated_at < b.updated_at ? 1 : -1
        );
        handleInstanceResponse(sortedInstances);
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleInstanceResponse = (instances) => {
    if (searchParams.get("instance_id")) {
      let found = instances.find(
        (item) => item.id == searchParams.get("instance_id")
      );
      if (found) {
        setSelectedInstance(found);
      }
    } else {
      if (instances.length > 0) {
        firstLoginRef.current = true;
        const latestInstanceCreated = instances[0];
        setSearchParams((params) => {
          params.set("instance_id", latestInstanceCreated.id);
          return params;
        });

        setSelectedInstance(latestInstanceCreated);
        setLoading(false);
      }
    }
  };

  const handleGetProjectConfig = (callBack) => {
    getProjectConfiguration(
      selectedInstance.id,
      (response) => {
        callBack(response);
      },
      () => {
        showGenericError();
      }
    );
  };

  const handleGeSubscriptionDetails = () => {
    setLoading(true);
    getSubscriptionDetails(
      selectedInstance.id,
      (response) => {
        setLoading(false);
      },
      (error) => {
        if (error.response?.data?.message !== "No active subscriptions") {
          showGenericError();
        }
        setLoading(false);
      }
    );
  };

  const handleGetCurrentMau = () => {
    setLoading(true);
    getCurrentMau(
      selectedInstance.id,
      (response) => {
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    initializeFromParams();
    handleGetInstances();
    const intervalId = setInterval(() => {
      handleGetInstances();
    }, 600000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (!selectedInstance) {
      return;
    }

    if (projectType === TEST) {
      setSelectedProject(selectedInstance.test);
    } else {
      setSelectedProject(selectedInstance.production);
    }

    setSearchParams((prev) => {
      prev.set("env_type", projectType);
      return prev;
    });
  }, [projectType, selectedInstance]);

  useEffect(() => {
    if (!selectedInstance) {
      return;
    }

    getInstanceUserRole(
      selectedInstance.id,
      (response) => {},
      (error) => {
        console.log(error);
      }
    );

    handleGetProjectConfig((response) => {
      if (firstLoginRef.current) {
        firstLoginRef.current = false;
        const resp = response.data.configurations;
        let android = resp.find((item) => item.platform === ANDROID);
        let ios = resp.find((item) => item.platform === IOS);

        if (android?.configuration || ios?.configuration) {
          navigate({
            pathname: "/links",
            search: createSearchParams(searchParams).toString(),
          });
        } else {
          navigate({
            pathname: "/developers",
            search: createSearchParams(searchParams).toString(),
          });
        }
      }
      setLoading(false);
    });

    handleGeSubscriptionDetails();
    handleGetCurrentMau();
  }, [selectedInstance]);

  return (
    <ChatwootProvider>
      <LoaderComponent loading={loading}>
        <Container>
          <SideBarMenu
            setCreateNewProjectModalOpen={setCreateNewProjectModalOpen}
            createNewProjectModalOpen={createNewProjectModalOpen}
          />

          <OutletWrapper>
            <TestModeBanner projectType={projectType} />
            <NoSdkBanner />
            <NoSubscriptionBanner />
            <Outlet
              context={{
                projectType,
                setProjectType,
                loading,
                setLoading,
                addNewLinkModalOpen,
                setAddNewLinkModalOpen,
                createNewProjectModalOpen,
                setCreateNewProjectModalOpen,
                contactSaleModalOpen,
                setContactSaleModalOpen,
                addNewLinkForCampaignID,
                setAddNewLinkForCampaignID,
              }}
            />
          </OutletWrapper>
          {instances?.length == 0 && (
            <CreateFirstProjectModal setLoading={setLoading} />
          )}
          <AnimatePresence>
            {addNewLinkModalOpen && (
              <CreateNewLinkModal
                isMoldaOpen={addNewLinkModalOpen}
                setIsModalOpen={setAddNewLinkModalOpen}
              />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {createNewProjectModalOpen && (
              <CreateNewProjectModal
                isMoldaOpen={createNewProjectModalOpen}
                setIsModalOpen={setCreateNewProjectModalOpen}
              />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {contactSaleModalOpen && (
              <ContactSaleModal
                setLoading={setLoading}
                setIsModalOpen={setContactSaleModalOpen}
              />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {addNewLinkForCampaignID && (
              <CreateNewLinkModal
                setIsModalOpen={() => {
                  setAddNewLinkForCampaignID(null);
                }}
                campaignID={addNewLinkForCampaignID}
              />
            )}
          </AnimatePresence>
        </Container>
      </LoaderComponent>
    </ChatwootProvider>
  );
}

export default App;

const Container = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
`;
const AnimatedDiv = styled(motion.div)``;

const OutletWrapper = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
`;
