//sidebarNav options
export const SETTING = "Project settings";
export const CONFIGURATION = "Configuration";
export const NOTIFICATIONS = "Notifications";
export const MESSAGES = "Messages";
export const LINKS = "Links";
export const CAMPAIGNS = "Campaigns";
export const USERS = "Users";
export const EVENTS = "Events";
export const INTEGRATIONS = "Developers";
export const MEMBERS = "Members";
export const SUBSCRIPTION = "Subscription";
export const DEVELOPERS = "Developers";
export const AUDIENCE = "Audience";
export const HELP = "Get live help";
export const DOMAIN = "domain";

//desktop radio , link-type select
export const GENERATED = "generated";
export const CUSTOM = "Custom";
export const DEFAULT = "default";
export const ACTIVE = "active";
export const INACTIVE = "inactive";
export const REFERRALS = "referrals";

//android app radio
export const INSTORE = "in_store";

//header switch
export const TEST = "Test";
export const PRODUCTION = "Production";
export const WEB_SWITCH = "Web";
export const SDK = "SDK";
export const LIVE = "live";
export const APPS = "Apps";

export const SUCCESS = "success";
export const INFO = "info";
export const WARNING = "warning";

//social mediaType
export const FACEBOOK = "facebook";
export const X = "x";
export const LINKEDIN = "linkedin";
export const GOOGLE = "google";
export const META = "meta";
export const TIKTOK = "tiktok";
export const GROVS = "grovs";
export const QUICK_LINK = "quick-link";

//image Type
export const FILE = "file";
export const LINK = "link";

//SDK TYPE
export const COCOAPODS = "cocoapods";
export const SPM = "spm";
export const GRADLE = "gradle";
export const SCENE_DELEGATE = "scene_delegate";
export const REACT_NATIVE = "react_native";
export const APP_DELEGATE = "app_delegate";
export const NPM = "npm";
export const YARN = "yarn";
export const NATIVE = "native";

//variables for calls
export const IOS = "ios";
export const WEB = "web";
export const MOBILE = "mobile";
export const ANDROID = "android";
export const DESKTOP = "desktop";
export const PHONE = "phone";
export const TABLET = "tablet";
export const MAC = "mac";
export const WINDOWS = "windows";

export const MEMBER = "member";
export const ARCHIVE = "archive";
export const CANCEL_PLAN = "cancel_plan";
export const REMOVE_ACCOUNT = "remove_account";
export const ARCHIVE_LINK = "archive_link";
export const DELETE_PROJECT = "delete_project";
export const REMOVE_MESSAGE = "remove_message";
//SETTINGS OPTIONS
export const PLAN_OPTION = "planSettings";
export const MEMBERS_OPTION = "membersSettings";

export const DEFAULT_PALCEHOLDER_LINK =
  "https://appssemble-assets.s3.eu-north-1.amazonaws.com/linksquared/social-media-placeholder.jpg";

export const START = "start";
export const CENTER = "center";
export const END = "end";
export const SMALL = "Small";
export const MEDIUM = "Medium";
export const LARGE = "Large";
export const FIT = "Fit";
export const SQUARE = "Square";
export const ROUND = "Round";
export const PILL = "Pill";

export const TEXT = "Text";
export const IMAGE = "Image";
export const VIDEO = "Video";
export const BUTTON = "Button";
export const MENU = "Menu";
export const BLOCKS = "Blocks";
export const STYLES = "Styles";
export const FULL = "Full";
export const SEPARATOR = "Separator";

export const ALL_USERS = "All Users";
export const NEW_USERS = "New Users";

export const CREATE_LINK = "select";
export const SELECT_LINK = "create";
