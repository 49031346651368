import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import CustomInput from "./CustomInput";
import CustomBigSubmitButton from "./CustomBigSubmitButton";
import { useUserContext } from "../../context/useUserContext";
import { useLocation, useNavigate } from "react-router-dom";
import { showErrorNotification } from "../../helper/Notifications";
import { ReactComponent as HideIcon } from "../../assets/icons/generic/eyeClosed.svg";
import { ReactComponent as ShowIcon } from "../../assets/icons/generic/eyeOpen.svg";
import { ReactComponent as GoogleIcon } from "../../assets/icons/generic/Google.svg";
import { ReactComponent as MicrosoftIcon } from "../../assets/icons/generic/Microsoft.svg";
import LocalStorage from "../../helper/LocalStorage";

const CustomSubmitButton = ({ text, onClick, disabled }) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled}>
      {text}
    </StyledButton>
  );
};

const CustomSSOSubmitButton = ({ text, onClick, disabled, icon }) => {
  return (
    <StyledSSOButton onClick={onClick} disabled={disabled}>
      {icon && <span>{icon}</span>}
      {text}
    </StyledSSOButton>
  );
};

const LoginCard = ({ setIsLoading }) => {
  const {
    userRef,
    loginUser,
    resetPassword,
    isOtpEnabled,
    getSSOAuthenticationLink,
  } = useUserContext();

  const location = useLocation();
  const navigate = useNavigate();
  const initialValues = {
    email: "",
    password: "",
    otp: "",
  };

  const [inputValues, setInputvalues] = useState(initialValues);
  const [otpEnabled, setOtpEnabled] = useState(false);
  const [passFiedlType, setPassFieldType] = useState("password");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setInputvalues({
      ...inputValues,
      [name]: value,
    });
  };

  const handleSSOAuthWindow = (
    authWindow,
    redirectURL,
    onSuccess,
    onFailure
  ) => {
    // Open a popup window for authentication
    authWindow = window.open(
      redirectURL,
      "SSOAuthWindow",
      "width=600,height=700"
    );

    if (!authWindow) {
      onFailure(
        new Error(
          "Popup window was blocked. Please allow popups for this site."
        )
      );
      return;
    }

    const currentHost = window.location.origin;
    let authCompleted = false;

    // Poll frequently to detect URL changes
    const checkInterval = setInterval(() => {
      if (authWindow.closed) {
        clearInterval(checkInterval);

        if (!authCompleted) {
          onFailure(
            new Error("Authentication window was closed before completion")
          );
        }
        return;
      }

      try {
        // This will throw if cross-origin
        const currentUrl = authWindow.location.href;

        // Check if we're back on our domain
        if (authWindow.location.origin === currentHost) {
          authCompleted = true;
          clearInterval(checkInterval);

          // Extract tokens from URL before any further redirects happen
          const url = new URL(currentUrl);
          const params = new URLSearchParams(url.search);

          // Get tokens from URL parameters
          const authToken =
            params.get("token") ||
            params.get("auth_token") ||
            params.get("access_token");
          const refreshToken = params.get("refresh_token");

          // Store tokens in sessionStorage
          if (authToken) {
            sessionStorage.setItem("authToken", authToken);
            console.log("Auth token stored in session");
          }

          if (refreshToken) {
            sessionStorage.setItem("refreshToken", refreshToken);
            console.log("Refresh token stored in session");
          }

          // Close the window
          authWindow.close();

          // Trigger success callback with the tokens
          onSuccess({ authToken, refreshToken, redirectUrl: currentUrl });
        }
      } catch (e) {
        // Cross-origin error - still in external auth flow
      }
    }, 50); // Poll frequently to catch the redirect quickly
  };

  const loginWithSSO = (sso) => {
    const width = 600;
    const height = 700;

    const y = window.top.outerHeight / 2 + window.top.screenY - height / 2;
    const x = window.top.outerWidth / 2 + window.top.screenX - width / 2;
    const authWindow = window.open(
      "",
      "SSOAuthWindow",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${y}, left=${x}`
    );

    setIsLoading(true);
    getSSOAuthenticationLink(
      sso,
      function (response) {
        setIsLoading(false);

        const redirectURL = response.data.redirect_url;
        handleSSOAuthWindow(
          authWindow,
          redirectURL,
          (returnData) => {
            console.log(returnData);
            // Success callback
            if (
              returnData.authToken != null &&
              returnData.refreshToken != null
            ) {
              LocalStorage.setAuthenthicationToken(returnData.authToken);
              LocalStorage.setRefreshToken(returnData.refreshToken);
            }

            navigate(`/`);
          },
          (error) => {
            // Failure callback
            console.error("SSO authentication failed:", error);
          }
        );
      },
      function (error) {
        setIsLoading(false);
        console.log("error", error);
      }
    );
  };

  const handleIsOtpEnabled = (email) => {
    isOtpEnabled(
      email,
      (response) => {
        const otpEnabled = response.data.otp_enabled;
        setOtpEnabled(otpEnabled);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const handleLogin = () => {
    setIsLoading(true);
    loginUser(
      inputValues.email,
      inputValues.password,
      inputValues.otp,
      function (response) {
        setIsLoading(false);
        if (location.state?.backTo) {
          navigate(`${location.state?.backTo}`);
        } else {
          navigate(`/`);
        }
      },
      function (error) {
        if (error.response.data.error == "invalid_grant") {
          showErrorNotification("Provided credentials are invalid");
        }
        setIsLoading(false);
        console.log("error");
      }
    );
  };

  const checkSignInDisabled = () => {
    if (otpEnabled) {
      return (
        inputValues.email.length < 4 ||
        inputValues.password.length < 3 ||
        inputValues.otp.length < 6
      );
    } else {
      return inputValues.email.length < 4 || inputValues.password.length < 3;
    }
  };

  const handleShowPassword = () => {
    if (passFiedlType === "password") {
      setPassFieldType("text");
    } else {
      setPassFieldType("password");
    }
  };

  const handleSignInBtn = () => {
    handleLogin();
  };

  const handleCreateAccountBtn = () => {
    navigate(`/register`);
  };

  const handleForgotPasswordBtn = () => {
    navigate(`/reset_password`);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleIsOtpEnabled(inputValues.email);
    }, 250);
    return () => {
      clearTimeout(timeout);
    };
  }, [inputValues.email]);

  return (
    <Card>
      <TitleContainer>
        <h2>Authenticate</h2>
      </TitleContainer>

      <CustomSSOSubmitButton
        text={"Continue with Google"}
        onClick={() => {
          loginWithSSO("google_oauth2");
        }}
        icon={<GoogleIcon />}
      />
      <CustomSSOSubmitButton
        text={"Continue with Microsoft"}
        onClick={() => {
          loginWithSSO("microsoft_office365");
        }}
        icon={<MicrosoftIcon />}
      />
      <OrSSOContainer>
        <p>or</p>
      </OrSSOContainer>
      <InputsWrapper>
        <CustomInput
          label={"Email"}
          placeholder="Enter your e-mail adress"
          name={"email"}
          value={inputValues.email}
          onChange={handleInputChange}
        />
        <Line>
          <CustomInput
            type={passFiedlType}
            label={"Password"}
            placeholder="Enter your password"
            name={"password"}
            value={inputValues.password}
            onChange={handleInputChange}
            bottomActionText={"Forgot password?"}
            bottomActionClick={() => handleForgotPasswordBtn()}
            customButton={
              <ShowPasswordBtn onClick={() => handleShowPassword()}>
                {passFiedlType == "password" ? <HideIcon /> : <ShowIcon />}
              </ShowPasswordBtn>
            }
          />
        </Line>
        {otpEnabled && (
          <Line>
            <CustomInput
              type={"text"}
              label={"OTP Code"}
              placeholder="Enter your OTP code"
              name={"otp"}
              value={inputValues.otp}
              onChange={handleInputChange}
            />
          </Line>
        )}
      </InputsWrapper>
      <SbmitWrapper>
        <CustomSubmitButton
          disabled={checkSignInDisabled()}
          text={"Sign in"}
          onClick={handleSignInBtn}
        />
        <p>
          Not a member?{" "}
          <span onClick={() => handleCreateAccountBtn()}>
            Create an account
          </span>
        </p>
      </SbmitWrapper>
    </Card>
  );
};

export default LoginCard;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  min-width: 530px;
  background: ${lightThemeColors.background};
  min-height: 550px;
  gap: 20px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: auto;
  margin-top: auto;
  box-shadow: 0px 0px 40px 0px rgba(151, 168, 190, 0.2);
  z-index: 1;
  @media (max-width: 650px) {
    width: 100%;
    min-width: unset;
  }
`;

const OrSSOContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px; /* Adjust height as needed */

  p {
    font-size: 14px !important;
    margin: 0;
    color: ${lightThemeColors.darkColorFaded60}!important;
  }
`;

const StyledButton = styled.button`
  width: 100%;
  color: white;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  text-align: center;
  padding: 12px;
  border-radius: 4px;
  border: none;
  background: ${lightThemeColors.primaryColor};
  cursor: pointer;
  &:disabled {
    opacity: 0.3;
  }
`;

const StyledSSOButton = styled.button`
  width: 100%;
  color: ${lightThemeColors.darkColor};
  font-size: 16px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  padding: 0px;
  border-radius: 4px;
  background: ${lightThemeColors.primaryColor};
  cursor: pointer;
  display: flex; /* Use flexbox to align items */
  align-items: center; /* Vertically center the content */
  justify-content: center; /* Horizontally center the content */
  gap: 8px; /* Space between the icon and text */

  &:disabled {
    opacity: 0.3;
  }

  background: ${lightThemeColors.background};
  border: 1px solid ${lightThemeColors.darkColorFullFaded};

  svg {
    margin-top: 5px;
    width: 30px;
    height: 30px;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 20px;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 900;
    font-size: 25px;
  }
  h2 {
    color: ${lightThemeColors.darkColorFaded};
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
  }
`;
const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const ForgotPwd = styled.div`
  width: fit-content;
  p {
    width: fit-content;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    color: ${lightThemeColors.primaryColor};
    cursor: pointer;
  }
`;
const SbmitWrapper = styled.div`
  margin-top: auto;

  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  p {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }
  span {
    margin-left: 2px;
    font-weight: 700;
    color: ${lightThemeColors.primaryColor};
    cursor: pointer;
  }
`;
const Line = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

const ShowPasswordBtn = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  jutify-content: center;
  svg {
    width: 16px;
    height: 16px;
    color: ${lightThemeColors.inputSubtitleColor};
  }
`;
