import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../../components/configurationComponents/ConfigurationSectionHeader";
import { ReactComponent as IOSIcon } from "../../assets/icons/generic/AppleIconSmall.svg";
import SubcategoryTitleWrapperWithNumber from "../../components/Generic/SubcategoryTittleWrapperWithNumber";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import SecondaryButton from "../../components/Generic/SecondaryButton";
import { ReactComponent as CopyIcon } from "../../assets/icons/generic/CopyIcon.svg";
import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg";
import { ReactComponent as RemoveIcon } from "../../assets/icons/generic/removeIcon.svg";
import ReactNativeiOSText from "./ReactNativeiOSText";

import PrimaryButton from "../../components/Generic/PrimaryButton";
import {
  APP_DELEGATE,
  COCOAPODS,
  IOS,
  SCENE_DELEGATE,
  REACT_NATIVE,
  SPM,
  NPM,
  YARN,
} from "../../constants/OptionsConstants";
import OptionButton from "../../components/Generic/OptionButton";
import { useExpanded } from "../../hooks/useExpanded";
import { useInstanceContext } from "../../context/useInstanceContext";
import {
  createSearchParams,
  Link,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import {
  showErrorNotification,
  showGenericError,
  showSuccessNotification,
} from "../../helper/Notifications";
import { copyToClipboard } from "../../helper/copy";
import { openInNewTab } from "../../helper/Utils";
import { Tooltip } from "react-tooltip";
import { isValidBundleID } from "../../helper/ValidatorsHelper";
import AppDelegateText from "./AppDelegateText";
import CodeBlock from "./CodeBlock";
import SceneDelegateText from "./SceneDelegateText";
import { useConfigurationContext } from "../../context/useConfigurationContext";

const IosSDK = () => {
  const {
    selectedInstance,
    setProjectIosConfiguration,
    instanceConfig,
    getProjectConfiguration,
    setProjectIosPushConfiguration,
  } = useInstanceContext();
  const { projectRedirectsConfig } = useConfigurationContext();

  const { setLoading } = useOutletContext();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [iosConfig, setIosConfig] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const [bundleId, setBundleId] = useState("");
  const [appleAppPrefix, setAppleAppPrefix] = useState("");
  const [urlScheme, setUrlScheme] = useState("");

  const [pushNotificationCertificateFile, setPushNotificationCertificateFile] =
    useState(null);
  const [certificatePassword, setCertificatePassword] = useState("");
  const [certificate, setCertificate] = useState(null);

  const [asociatedDomainProd, setAsociatedDomainProd] = useState("");
  const [asociatedDomainTest, setAsociatedDomainTest] = useState("");

  const [expanded, expand] = useExpanded(false);
  const [sdkType, setSdkType] = useState(SPM);
  const [sdkMethod, setSdkMethod] = useState(APP_DELEGATE);

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const divRef = useRef(null);
  const inputFileRef = useRef();

  const handleMouseMove = (event) => {
    const x = event.clientX;
    const y = event.clientY;
    setPosition({ x, y });
  };

  const containerStyle = {
    maxWidth: "635px",
  };

  const SDK_TYPE_LIST = [
    {
      text: "SPM",
      value: SPM,
      labelText: "Show me how to integrate a SPM package on iOS",
      link: "https://docs.grovs.io/s/docs/doc/how-to-integrate-a-spm-package-Dc7KFyV2XG",
      inputValue: "https://github.com/grovs-io",
      subtitle:
        "To integrate a new SPM package on iOS, go to Xcode, select 'File' -> 'Swift Packages' -> 'Add Package Dependency...', then paste the package's URL.",
    },
    {
      text: "Cocoapods",
      value: COCOAPODS,
      labelText: "Show me how to integrate the library using Cocoapods",
      link: "https://docs.grovs.io/s/docs/doc/how-integrate-a-cocoapods-library-uXVATugqj9",
      inputValue: "pod 'Grovs'",
      subtitle:
        "To integrate a new CocoaPods package, add the package name and version to your Podfile, then run pod install in your terminal.",
    },
    {
      text: "NPM",
      value: NPM,
      labelText: "Show me how to integrate the library using NPM",
      link: "https://docs.grovs.io/s/docs/doc/integrating-an-npm-package-into-a-react-native-project-ZcPQGssbqW",
      inputValue: "npm install react-native-grovs-wrapper",
      subtitle:
        "Integrating npm packages into a React Native app is a straightforward process that allows you to extend your app’s functionality using our SDK.",
    },
    {
      text: "YARN",
      value: YARN,
      labelText: "Show me how to integrate the library using YARN",
      link: "https://docs.grovs.io/s/docs/doc/integrating-an-yarn-package-into-a-react-native-project-L7fhtLxIAO",
      inputValue: "yarn add react-native-grovs-wrapper",
      subtitle:
        "Integrating yarn packages into a React Native app is a straightforward process that allows you to extend your app’s functionality using our SDK.",
    },
  ];

  const INTEGRATION_METHOD = [
    {
      text: "App Delegate",
      value: APP_DELEGATE,
    },
    {
      text: "Scene Delegate",
      value: SCENE_DELEGATE,
    },
    {
      text: "React Native",
      value: REACT_NATIVE,
    },
  ];

  const getValuesForType = () => {
    let found = SDK_TYPE_LIST.find((item) => item.value === sdkType);
    return found;
  };

  const handleSelectSdkType = (value) => {
    setSdkType(value);
  };

  const handleSelectSdkMethod = (value) => {
    setSdkMethod(value);
  };

  const handleInputFile = () => {
    setCertificate(null);
    inputFileRef.current.click();
  };

  const handleSetPushNotificationCertificateFile = (e) => {
    setCertificate(null);
    setPushNotificationCertificateFile(e.currentTarget.files[0]);
  };

  const handleGetProjectConfig = () => {
    setLoading(true);
    getProjectConfiguration(
      selectedInstance.id,
      (response) => {
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleSetIosConfig = (instanceId, enabled, bundle_id, app_prefix) => {
    var dataObject = {
      enabled: enabled,
      bundle_id: bundle_id,
      app_prefix: app_prefix,
    };

    if (
      (bundle_id && isValidBundleID(bundleId) && app_prefix) ||
      (!bundle_id && !app_prefix)
    ) {
      setLoading(true);
      setProjectIosConfiguration(
        instanceId,
        dataObject,
        (response) => {
          setIosConfig(response.data.config);
          showSuccessNotification("Saved");
          setLoading(false);
          handleGetProjectConfig();
        },
        () => {
          showGenericError();
          setLoading(false);
        }
      );
    } else {
      showErrorNotification(
        "You need to fill in both the bundle ID and Apple App Prefix, before you can save the changes."
      );
      return;
    }
  };

  const handleSave = () => {
    let enabled = true;
    if (iosConfig) {
      enabled = iosConfig.enabled;
    }

    let detailsChanged = monitorDetailsChange(iosConfig);
    let certificatChanged = monitorCertificatChange(iosConfig);

    if (detailsChanged) {
      handleSetIosConfig(
        selectedInstance.id,
        enabled,
        bundleId,
        appleAppPrefix
      );
    }

    if (certificatChanged) {
      if (certificatePassword === "" && !pushNotificationCertificateFile) {
        showErrorNotification(
          "You need to fill in both the certificate and password, before you can save the changes."
        );
        return;
      }

      if (!bundleId || !appleAppPrefix) {
        showErrorNotification(
          `You need to fill "Register the app" section before adding the certificate`
        );
        return;
      }

      handleSetIOSPushConfig(
        selectedInstance.id,
        certificatePassword,
        pushNotificationCertificateFile
      );
    }
  };

  const handleSetIOSPushConfig = (
    projectId,
    certificatePassword,
    certificateFile
  ) => {
    let formData = new FormData();
    if (certificatePassword !== "" && certificateFile) {
      formData.append("push_certificate_password", certificatePassword);
      formData.append("push_certificate", certificateFile);
    }

    setLoading(true);

    setProjectIosPushConfiguration(
      projectId,
      formData,
      (response) => {
        setCertificatePassword("");
        setPushNotificationCertificateFile(null);
        showSuccessNotification("Certificate added/changed");
        setLoading(false);
        handleGetProjectConfig();
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const initializeValues = (config) => {
    setUrlScheme(selectedInstance.uri_scheme);

    setAsociatedDomainProd("applinks:" + selectedInstance.production.domain);
    setAsociatedDomainTest("applinks:" + selectedInstance.test.domain);

    if (!config || !config.configuration) {
      setBundleId("");
      setAppleAppPrefix("");

      setCertificate(null);
      setCertificatePassword("");
      setPushNotificationCertificateFile(null);
    } else {
      setBundleId(config.configuration.bundle_id);
      setAppleAppPrefix(config.configuration.app_prefix);
      if (config.configuration.push_configuration) {
        setCertificate(config.configuration.push_configuration.certificate);
      } else {
        setCertificate(null);
        setCertificatePassword("");
        setPushNotificationCertificateFile(null);
      }
    }
  };

  const detailsChanged = (config) => {
    let detailsChanged = monitorDetailsChange(config);
    let certificatChanged = monitorCertificatChange(config);

    if (detailsChanged || certificatChanged) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  };

  const monitorDetailsChange = (config) => {
    if (!config || !config.configuration) {
      if (bundleId !== "" || appleAppPrefix !== "") {
        return true;
      } else {
        return false;
      }
    } else if (
      bundleId !== config?.configuration.bundle_id ||
      appleAppPrefix !== config?.configuration.app_prefix
    ) {
      return true;
    } else {
      return false;
    }
  };

  const monitorCertificatChange = (config) => {
    if (!config || !config.configuration) {
      if (pushNotificationCertificateFile || certificatePassword !== "") {
        return true;
      } else {
        return false;
      }
    } else if (
      pushNotificationCertificateFile != null &&
      certificatePassword !== ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleRemoveCertificate = () => {
    handleSetIOSPushConfig(selectedInstance.id, "", null);
  };

  const setOpenRedirectParams = () => {
    searchParams.append("expandRedirect", true);
    return createSearchParams(searchParams).toString();
  };

  useEffect(() => {
    if (!instanceConfig) {
      return;
    }

    let found = instanceConfig.find((config) => config.platform === IOS);
    if (instanceConfig.length > 0 && found) {
      setIosConfig(found);
      initializeValues(found);
    } else {
      setIosConfig(null);
      initializeValues(null);
    }
  }, [instanceConfig]);

  useEffect(() => {
    detailsChanged(iosConfig);
  }, [
    urlScheme,
    appleAppPrefix,
    bundleId,
    iosConfig,
    pushNotificationCertificateFile,
    certificatePassword,
  ]);

  return (
    <Container>
      <ConfigurationSectionHeader
        icon={<IOSIcon />}
        title={"iOS SDK"}
        handleExpand={expand}
        expanded={expanded}
        customInfoText={
          iosConfig &&
          !projectRedirectsConfig?.ios?.phone?.enabled &&
          isSaveDisabled && (
            <IntegrationSuccessMessage>
              <Link
                to={{
                  pathname: "/configuration",
                  search: setOpenRedirectParams(),
                }}
              >
                Enable iOS app redirects{" "}
              </Link>
              so that links open directly in the app.
            </IntegrationSuccessMessage>
          )
        }
        showValidation
        isValid={iosConfig?.configuration?.bundle_id}
        unsavedChanges={!isSaveDisabled}
        customButton={
          isSaveDisabled === false && (
            <PrimaryButton
              disabled={isSaveDisabled}
              bgVariant={lightThemeColors.background}
              styled={{ color: lightThemeColors.primaryColor }}
              text={"Save"}
              onClick={(e) => handleSave()}
            />
          )
        }
      />

      {expanded && (
        <ContentContainer>
          <Tooltip
            opacity={1}
            noArrow
            position={position}
            role="tooltip"
            border={"none"}
            style={{
              zIndex: "100",
              opacity: 1,
              background: lightThemeColors.primaryColor,
              color: lightThemeColors.background,
              padding: "5px",
              border: "none",
            }}
            id={"iosSDK-tooltip"}
          />
          <SubcategoryContent>
            <Line>
              <SubcategoryTitleWrapperWithNumber
                number={"1"}
                text="Register the app "
              />
            </Line>
            <Line>
              <InputFieldWithValidation
                title={"Bundle ID"}
                subtitle={
                  "The bundle identifier (bundle ID) of an iOS app can be found in the Xcode project settings or in the 'Info.plist' file, and it uniquely identifies the app in the Apple ecosystem."
                }
                placeholder={"Enter a bundle ID..."}
                style={containerStyle}
                label={"Show me how to get the bundle id"}
                labelAction={() =>
                  openInNewTab(
                    "https://docs.grovs.io/s/docs/doc/how-to-get-the-bundle-identifier-z6xPUY0mS4"
                  )
                }
                valid={isValidBundleID(bundleId)}
                inputValue={bundleId}
                handleInputChange={(e) => setBundleId(e.currentTarget.value)}
              />
            </Line>
            <Line>
              <InputFieldWithValidation
                title={"Apple App Prefix"}
                subtitle={
                  "To find the Apple App Prefix, log in to your Apple Developer account, go to Certificates, Identifiers & Profiles, click on your App ID, and the prefix (Team ID) will be listed there."
                }
                placeholder={"Enter Apple App Prefix..."}
                style={containerStyle}
                label={"Show me how to get the Apple App Prefix"}
                labelAction={() =>
                  openInNewTab(
                    "https://docs.grovs.io/s/docs/doc/how-to-get-the-apple-app-prefix-I8eh7hxSP1"
                  )
                }
                valid={appleAppPrefix.length > 2}
                inputValue={appleAppPrefix}
                handleInputChange={(e) =>
                  setAppleAppPrefix(e.currentTarget.value)
                }
              />
            </Line>
          </SubcategoryContent>
          <Separator />
          <SubcategoryContent>
            <Line>
              <SubcategoryTitleWrapperWithNumber
                number={"2"}
                text="Configure URL Scheme "
              />
            </Line>
            <Line>
              <InputFieldWithValidation
                title={"URL Scheme"}
                subtitle={
                  "Add the URL Scheme to your app. To add a new URL scheme in an iOS app, update the 'Info.plist' file with the desired scheme under 'URL types'."
                }
                readOnly
                noValidation
                style={containerStyle}
                label={"Show me how to add a new URL Scheme on iOS"}
                labelAction={() =>
                  openInNewTab(
                    "https://docs.grovs.io/s/docs/doc/how-to-add-a-new-url-scheme-8txBAYCmpy"
                  )
                }
                inputValue={urlScheme}
                handleInputChange={(e) => setUrlScheme(e.currentTarget.value)}
                customButton={
                  <SecondaryButton
                    icon={<CopyIcon />}
                    text={"Copy"}
                    onClick={() => copyToClipboard(urlScheme)}
                  />
                }
              />
            </Line>
            <Line>
              <InputFieldWithValidation
                title={"Asociated Domains"}
                subtitle={
                  "To add a new associated domain to your iOS app, enable the Associated Domains capability in Xcode, and add the domain below to the list."
                }
                readOnly
                noValidation
                style={containerStyle}
                labelAction={() => alert("implement")}
                inputValue={asociatedDomainProd}
                customButton={
                  <SecondaryButton
                    icon={<CopyIcon />}
                    text={"Copy"}
                    onClick={() => copyToClipboard(asociatedDomainProd)}
                  />
                }
              />
            </Line>
            <Line>
              <InputFieldWithValidation
                readOnly
                noValidation
                style={containerStyle}
                inputValue={asociatedDomainTest}
                customButton={
                  <SecondaryButton
                    icon={<CopyIcon />}
                    text={"Copy"}
                    onClick={() => copyToClipboard(asociatedDomainTest)}
                  />
                }
                label={"Show me how to add an associated domain"}
                labelAction={() =>
                  openInNewTab(
                    "https://docs.grovs.io/s/docs/doc/how-to-add-an-associated-domain-rLY2RTKvda"
                  )
                }
              />
            </Line>
          </SubcategoryContent>
          <Separator />
          <SubcategoryContent>
            <Line>
              <SubcategoryTitleWrapperWithNumber
                number={"3"}
                text="Add the SDK"
              />
            </Line>

            <InfoText>
              <h3>Integration method</h3>
            </InfoText>

            <Line>
              {SDK_TYPE_LIST.map((item, index) => (
                <OptionButton
                  key={index}
                  text={item.text}
                  selected={sdkType === item.value}
                  onClick={() => handleSelectSdkType(item.value)}
                />
              ))}
            </Line>

            <Line>
              <InputFieldWithValidation
                style={containerStyle}
                inputValue={getValuesForType().inputValue}
                noValidation
                label={getValuesForType().labelText}
                labelAction={() => openInNewTab(getValuesForType().link)}
                subtitle={getValuesForType().subtitle}
                readOnly
                customButton={
                  <SecondaryButton
                    icon={<CopyIcon />}
                    text={"Copy"}
                    onClick={() =>
                      copyToClipboard(getValuesForType().inputValue)
                    }
                  />
                }
              />
            </Line>
          </SubcategoryContent>
          <Separator />
          <SubcategoryContent>
            <Line>
              <SubcategoryTitleWrapperWithNumber
                number={"4"}
                text="Push notifications"
              />
            </Line>

            {certificate ? (
              <>
                <InfoText>
                  <h3 style={{ margin: 0 }}>{certificate}</h3>
                </InfoText>

                <Line>
                  <SecondaryButton
                    text={"Replace"}
                    icon={<PlusIcon />}
                    onClick={() => handleInputFile()}
                  />
                  <SecondaryButton
                    text={"Remove"}
                    icon={<RemoveIcon />}
                    variant={lightThemeColors.authentication.inputErrorColor}
                    onClick={() => handleRemoveCertificate()}
                  />
                </Line>
              </>
            ) : (
              <>
                <Line>
                  <Line>
                    <InfoText>
                      <h3 style={{ margin: 0 }}>
                        {pushNotificationCertificateFile?.name}
                      </h3>
                    </InfoText>
                    <SecondaryButton
                      text={
                        pushNotificationCertificateFile
                          ? "Replace certificate"
                          : "Upload certificate"
                      }
                      icon={<PlusIcon />}
                      onClick={() => handleInputFile()}
                    />
                  </Line>
                </Line>
                <Line>
                  <InputFieldWithValidation
                    title={"P8 APN Key ID"}
                    style={containerStyle}
                    noValidation
                    type={"password"}
                    inputValue={certificatePassword}
                    handleInputChange={(e) =>
                      setCertificatePassword(e.currentTarget.value)
                    }
                  />
                </Line>
              </>
            )}
            <input
              type="file"
              ref={inputFileRef}
              style={{ display: "none" }}
              onChange={(e) => handleSetPushNotificationCertificateFile(e)}
            />
          </SubcategoryContent>

          <Separator />

          <SubcategoryContent>
            <Line>
              <SubcategoryTitleWrapperWithNumber
                number={"5"}
                text="Initialize SDK"
              />
            </Line>
            <Line>
              {INTEGRATION_METHOD.map((item, index) => (
                <OptionButton
                  key={index}
                  text={item.text}
                  selected={sdkMethod === item.value}
                  onClick={() => handleSelectSdkMethod(item.value)}
                />
              ))}
            </Line>
            <InfoText>
              In order to leverage the full capabilities of our application, we
              need to properly initialize the SDK. This step is crucial as it
              ensures seamless integration and functionality across various
              platforms and environments.
            </InfoText>

            <Line style={{ alignItems: "start" }}>
              {sdkMethod === APP_DELEGATE ? (
                <AppDelegateText onMouseMove={handleMouseMove} ref={divRef} />
              ) : sdkMethod === SCENE_DELEGATE ? (
                <SceneDelegateText onMouseMove={handleMouseMove} ref={divRef} />
              ) : sdkMethod === REACT_NATIVE ? (
                <ReactNativeiOSText
                  onMouseMove={handleMouseMove}
                  ref={divRef}
                />
              ) : (
                <></>
              )}
            </Line>
            <Line>
              <CustomLabel
                onClick={() =>
                  openInNewTab(
                    "https://docs.grovs.io/s/docs/doc/ios-WYi1Q5lGb6"
                  )
                }
              >
                Show me more details about the SDK and it’s methods
              </CustomLabel>
              <SecondaryButton
                style={{ marginLeft: "auto" }}
                icon={<CopyIcon />}
                text={"Copy"}
                onClick={() =>
                  copyToClipboard(
                    document.getElementById("iosSDKText").innerText
                  )
                }
              />
            </Line>
          </SubcategoryContent>
        </ContentContainer>
      )}
    </Container>
  );
};

export default IosSDK;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 30px;
`;

const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 40px;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
  margin: 30px 0;
`;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  max-width: 635px;
  h3 {
    margin-top: 10px;
    color: ${lightThemeColors.darkColorFaded};
    font-size: 15px;
  }
`;
const TextCodeContainer = styled.pre`
  display: flex;
  flex-direction: column;
  border: 1px solid ${lightThemeColors.menuBorder};
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${lightThemeColors.darkColorFaded60};
  overflow: hidden;
  white-space: pre-wrap;
  span {
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-weight: 600;
    color: ${lightThemeColors.primaryColor};
  }
`;
const CustomLabel = styled.label`
  margin-top: -20px;
  cursor: pointer;
  color: ${lightThemeColors.inputLabelColor};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const NormalText = styled.pre`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${lightThemeColors.darkColorFaded60};
`;
const BlueText = styled(NormalText)`
  font-weight: 600;
  color: ${lightThemeColors.primaryColor};
`;

const IntegrationSuccessMessage = styled.p`
font-size:14px;
color:${lightThemeColors.darkColor};
a{
text-decoration:none;
color:${lightThemeColors.primaryColor};}
}

`;
