import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";

// Create a context for Chatwoot visibility
const ChatwootContext = createContext();

// Provider component
export const ChatwootProvider = ({ children }) => {
  const [isChatVisible, setIsChatVisible] = useState(false);
  const [isChatwootReady, setIsChatwootReady] = useState(false);
  const manuallyOpened = useRef(false);

  // Load Chatwoot script dynamically
  useEffect(() => {
    const script = document.createElement("script");
    const BASE_URL = "https://chat.services-appssemble.top";
    script.src = BASE_URL + "/packs/js/sdk.js";
    script.defer = true;
    script.async = true;

    script.onload = () => {
      // Initialize Chatwoot
      window.chatwootSettings = {
        hideMessageBubble: true,
        position: "right",
        locale: "en",
        type: "expanded_bubble",
      };

      window.chatwootSDK.run({
        websiteToken: "6ejajsCkV6yKZTHjc3mU6Kdy",
        baseUrl: BASE_URL,
        hideMessageBubble: true,
      });

      // Wait for Chatwoot to fully initialize
      const checkChatwootReady = setInterval(() => {
        if (window.$chatwoot) {
          clearInterval(checkChatwootReady);
          setIsChatwootReady(true);
          console.log("Chatwoot SDK loaded successfully");

          // Add a small delay to ensure all Chatwoot event handlers are set up
          setTimeout(() => {
            // Add our own custom event handler to the Chatwoot iframe
            setupWidgetMonitoring();
          }, 1000);
        }
      }, 100);
    };

    document.body.appendChild(script);

    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []);

  // Function to set up mutation observer to monitor widget visibility changes
  const setupWidgetMonitoring = () => {
    // The main approach: Monitor DOM changes to detect when the widget appears/disappears
    const observer = new MutationObserver((mutations) => {
      const isWidgetVisible = checkWidgetState();
      if (isWidgetVisible !== isChatVisible) {
        console.log("Widget visibility changed to:", isWidgetVisible);
        setIsChatVisible(isWidgetVisible);
      }
    });

    // Observe the body for changes, as Chatwoot injects its elements dynamically
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ["class", "style"],
    });

    // Initial check
    const initialState = checkWidgetState();
    setIsChatVisible(initialState);

    return () => {
      observer.disconnect();
    };
  };

  // Check if widget is visible using multiple reliable methods
  const checkWidgetState = () => {
    if (!window.$chatwoot) return false;

    // Method 1: Direct API check if available
    if (typeof window.$chatwoot.isOpen === "function") {
      return window.$chatwoot.isOpen();
    }

    // Method 2: Check multiple possible selectors
    const possibleSelectors = [
      ".woot-widget-holder.woot--expand", // Common class
      ".woot-widget-holder[style*='display: block']", // Style-based
      ".woot-widget-bubble.woot--expand", // Alternative class
      "iframe[data-testid='widgetFrame']:not([style*='display: none'])", // Iframe approach
      ".woot--expand", // Minimal class
      "#chatwoot-widget[style*='display: block']", // ID-based
      ".chatwoot-widget-box[style*='display: block']", // Box class
    ];

    for (const selector of possibleSelectors) {
      const element = document.querySelector(selector);
      if (element) return true;
    }

    // Method 3: Check iframe visibility
    const chatwootIframes = document.querySelectorAll(
      "iframe[src*='chat.services-appssemble.top']"
    );
    for (const iframe of chatwootIframes) {
      // Check if the iframe is visible (not display:none or visibility:hidden)
      const style = window.getComputedStyle(iframe);
      if (
        style.display !== "none" &&
        style.visibility !== "hidden" &&
        style.opacity !== "0"
      ) {
        return true;
      }
    }

    return false;
  };

  // Open chat function with retry mechanism
  const openChat = () => {
    if (!isChatwootReady || !window.$chatwoot) {
      console.warn("Chatwoot not ready yet");
      return;
    }

    manuallyOpened.current = true;

    try {
      window.$chatwoot.toggle("open");

      // Set state immediately for better UX
      setIsChatVisible(true);

      // Verify that the widget actually opened
      setTimeout(() => {
        const isActuallyOpen = checkWidgetState();
        if (!isActuallyOpen) {
          console.log("Widget didn't open on first try, retrying...");
          // Retry with alternative method
          window.$chatwoot.toggle("open");

          // Check again after a short delay
          setTimeout(() => {
            setIsChatVisible(checkWidgetState());
          }, 300);
        }
      }, 300);
    } catch (error) {
      console.error("Error opening Chatwoot widget:", error);
    }
  };

  // Close chat function
  const closeChat = () => {
    if (!isChatwootReady || !window.$chatwoot) return;

    manuallyOpened.current = false;

    try {
      window.$chatwoot.toggle("close");
      setIsChatVisible(false);
    } catch (error) {
      console.error("Error closing Chatwoot widget:", error);
    }
  };

  // Toggle function
  const toggleChat = () => {
    if (isChatVisible) {
      closeChat();
    } else {
      openChat();
    }
  };

  return (
    <ChatwootContext.Provider
      value={{
        isChatVisible,
        openChat,
        closeChat,
        toggleChat,
        isChatwootReady,
      }}
    >
      {children}
    </ChatwootContext.Provider>
  );
};

// Custom hook to use the Chatwoot context
export const useChatwoot = () => useContext(ChatwootContext);
