import React, { useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import CustomSearchInput from "../../components/linksComponents/CustomSearchInput";
import DatePickerWithOptions from "../../components/linksComponents/DatePickerWithOptions";
import AudienceOverview from "./AudienceOverview";
import AudienceLinksView from "./AudienceLinksView";

const AudiencePageContent = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  totalPages,
  setPage,
  page,
  setSort,
  searchInputValue,
  visitorMetrics,
  agregatedVisitorMetrics,
  sort,
  handleSearchInputValue,
  handleOpenAudienceModal,
  audienceType,
}) => {
  return (
    <Container>
      <Line style={{ zIndex: "3" }}>
        <CustomSearchInput
          id="audienceSearchInput"
          style={{ maxWidth: "300px" }}
          value={searchInputValue}
          setValue={handleSearchInputValue}
          palceholder="Search"
        />
        <Line style={{ marginLeft: "auto" }}>
          <DatePickerWithOptions
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
          />
        </Line>
      </Line>
      <AudienceOverview startDate={startDate} endDate={endDate} />
      <Separator />

      <AudienceLinksView
        totalPages={totalPages}
        setPage={setPage}
        page={page}
        setSort={setSort}
        visitorMetrics={visitorMetrics}
        agregatedVisitorMetrics={agregatedVisitorMetrics}
        sort={sort}
        handleOpenAudienceModal={handleOpenAudienceModal}
        audienceType={audienceType}
      />
    </Container>
  );
};

export default AudiencePageContent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  h2 {
    width: 100%;
  }
  gap: 30px;
`;

const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
`;
const Line = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
