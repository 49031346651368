import React from 'react'
import styled from 'styled-components';
import { ReactComponent as Grovs } from "../../assets/icons/linkTypeAdsIcons/Grovs.svg"
import GeneratedLink from './GeneratedLink';
import SecondaryButton from '../Generic/SecondaryButton';
import { copyToClipboard } from '../../helper/copy';
import { lightThemeColors } from '../../assets/styles/colors';
import { ReactComponent as CopyIcon } from "../../assets/icons/generic/CopyIcon.svg";
import SocialMediaButtonsGroup from '../configurationComponents/SocialMediaButtonsGroup';
import LableWithArrow from '../configurationComponents/LableWithArrow';
import GeneratedAdsPlatformLinkPreview from './GeneratedAdsPlatformLinkPreview';
import { QUICK_LINK } from '../../constants/OptionsConstants';


const GeneratedQuickLinkPreview = ({ createdLink, socialMediaCardType, setSocialMediaCardType, displaySocialMediaCardForGeneratedLink }) => {
    let display = <ConfigList>
        <Line style={{ gap: '10px' }}>
            <Grovs />
            <AdPlatformTitle>
                Quick link
            </AdPlatformTitle>
        </Line>
        <InfoText>
            Here is the link that has been newly generated for you and how
            your new link will appear when shared across various platforms.
        </InfoText>
        <Separator />
        <AdPlatformTitle >
            Generated Link
        </AdPlatformTitle>
        <Line style={{ alignItems: "end", marginTop: "-10px" }} >
            <GeneratedLink
                style={{ maxWidth: "500px" }}
                generatedLink={createdLink?.access_path}
            />
            <SecondaryButton
                icon={<CopyIcon />}
                text={"Copy Link"}
                onClick={() => copyToClipboard(createdLink?.access_path)}
            />
        </Line>
        <Separator />

        <LableWithArrow
            text={"Here's a preview of your social media appearance."}
            arrowDirection={"down"}
            reverse

        />
        <SocialMediaButtonsGroup
            selectedOption={socialMediaCardType}
            setSelectedOption={setSocialMediaCardType}
        />
        {displaySocialMediaCardForGeneratedLink()}
    </ConfigList>

    if (createdLink.ads_platform !== QUICK_LINK) {
        display = <GeneratedAdsPlatformLinkPreview createdLink={createdLink} />
    }

    return (
        display
    )
}

export default GeneratedQuickLinkPreview

const ConfigList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  position: relative;
  height: 100%;
  padding:0 40px 30px 40px;
`;

const Line = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;

`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
  margin:10px 0;
`;
const AdPlatformTitle = styled.h3`
font-size: 16px;
font-weight: 700;
line-height: 24px;
text-align: left;
  color: ${lightThemeColors.darkColor};
`
const InfoText = styled.p`
 color: ${lightThemeColors.darkColorFaded30};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
`