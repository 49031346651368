import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import LocalStorage from "../helper/LocalStorage";
import { useUserContext } from "../context/useUserContext";
import LoaderComponent from "../components/Generic/LoaderComponent";
import { useSearchParams } from "react-router-dom";

const ProtectedRoute = ({ children, redirectPath = "/login" }) => {
  const { fetchCurrentUser, userRef } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); // Hook to access URL query parameters

  var authToken = LocalStorage.getAuthenticationToken();

  const token = searchParams.get("token");
  const refreshToken = searchParams.get("refresh_token");

  if (token && refreshToken) {
    // Store the tokens in LocalStorage or sessionStorage
    LocalStorage.setAuthenthicationToken(token);
    LocalStorage.setRefreshToken(refreshToken);

    authToken = LocalStorage.getAuthenticationToken();
  }

  useEffect(() => {
    if (authToken !== null) {
      fetchCurrentUser(
        function (response) {
          navigate({ redirectPath, search: location.search });
        },
        function (error) {
          navigate("/login", { state: { backTo: `${location.pathname}` } });
        }
      );
    }
  }, []);

  if (authToken == null) {
    return (
      <Navigate to={redirectPath} state={{ backTo: `${location.pathname}` }} />
    );
  }

  if (authToken) {
    return (
      <>{userRef.current ? children : <LoaderComponent loading={true} />}</>
    );
  }
};

export default ProtectedRoute;
