import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as UpSortIcon } from "../../assets/icons/generic/tableSortIcons/SortUp.svg";
import { ReactComponent as DownSortIcon } from "../../assets/icons/generic/tableSortIcons/SortDown.svg";
import { ReactComponent as DefaultSortIcon } from "../../assets/icons/generic/tableSortIcons/SortDefault.svg";
import { ReactComponent as ActionCellIcon } from "../../assets/icons/generic/ActionCellIcon.svg";
import { ReactComponent as ActionCellCampaignIcon } from "../../assets/icons/generic/ActionCellCampaignIcon.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/sidebar/InfoIcon.svg";
import {
  getPaginationRange,
  parseSecondsInHoursMinutesSeconds,
} from "../../helper/Utils";

import {
  Table,
  Header,
  HeaderRow,
  Body,
  Row,
  Cell,
  HeaderCell,
} from "@table-library/react-table-library/table";

import { lightThemeColors } from "../../assets/styles/colors";
import { useTheme } from "@table-library/react-table-library/theme";
import moment from "moment";
import TagsTooltip from "../../components/linksComponents/TagsTootip";

import { Tooltip } from "react-tooltip";
import {
  ACTIVE,
  INACTIVE,
  REFERRALS,
  USERS,
} from "../../constants/OptionsConstants";

const SortSwitch = ({ sortKey, sort, onClick, noSort }) => {
  if (noSort) {
    return;
  }
  return (
    <SortSwitchElement onClick={onClick}>
      {sortKey === sort.sortKey ? (
        sort.ascendent ? (
          <UpSortIcon />
        ) : (
          <DownSortIcon />
        )
      ) : (
        <DefaultSortIcon />
      )}
    </SortSwitchElement>
  );
};

const DisplayCellBasedOnType = ({ type, value, onClick, pinLeft }) => {
  var diplayValue = value;

  if (!value && value != 0) {
    diplayValue = "-";
  }

  var display = (
    <CustomCell onClick={onClick} pinLeft={pinLeft}>
      <p>{diplayValue}</p>
    </CustomCell>
  );

  const leftContentCell = (
    <LeftContentCell onClick={onClick} pinLeft={pinLeft}>
      <p
        style={
          type === "Name"
            ? {
              cursor: "pointer",
              fontWeight: "600",
              color: lightThemeColors.primaryColor,
              fontSize: "14px",
              lineHeight: "25px",
            }
            : {}
        }
      >
        {diplayValue}
      </p>
    </LeftContentCell>
  );

  const actionCell = (
    <ActionCell onClick={onClick} pinLeft={pinLeft}>
      <ActionCellCampaignIcon />
    </ActionCell>
  );

  const tagsCell = (
    <ActionCell pinLeft={pinLeft}>
      <TagsTooltip value={diplayValue} />
    </ActionCell>
  );

  const coloredCell = (
    <ColoredCell onClick={onClick} pinLeft={pinLeft}>
      <p>{diplayValue}</p>
    </ColoredCell>
  );

  switch (type) {
    case "Name":
      display = leftContentCell;
      break;
    case "Date":
      display = leftContentCell;
      break;
    case "action_cell":
      display = actionCell;
      break;
    case "tags_cell":
      display = tagsCell;
      break;
    case "colored_cell":
      display = coloredCell;
      break;

    default:
      break;
  }

  return <>{display}</>;
};

const CampaignsLinksView = ({
  totalPages,
  setPage,
  page,
  setSort,
  campaigns,
  sort,
  handleSelectedCampaign,
}) => {
  const [nodes, setNodes] = useState([]);


  const createNodesFromData = () => {
    if (!campaigns) {
      return;
    }

    const mapped = campaigns.campaigns.result.map((item) => {
      return {
        id: item.campaign.id,
        name: item.campaign.name,
        archived: item.campaign.archived,
        date: moment(item.campaign.created_at).format("MMM DD,YYYY"),
        view: item.metrics?.view,
        open: item.metrics?.open,
        install: item.metrics?.install,
        reinstall: item.metrics?.reinstall,
        reactivation: item.metrics?.reactivation,
        has_links: item.campaign.has_links
      };
    });

    setNodes(mapped);
  };

  const data = { nodes };

  const COLUMNS = [
    {
      label: "Name",
      sortKey: "name",
      renderCell: (item) => item.name,
      type: "Name",
      background: "rgba(244, 249, 255, 1)",
      action: (item) => {
        handleSelectedCampaign(item);
      },
      alignStart: true,
      pinLeft: true,
    },

    {
      label: "Views",
      noSort: false,
      sortKey: "view",
      renderCell: (item) => {
        return item.view;
      },
      type: "normal_cell",
      floatingInfo:
        "Indicates the number of times the campaign links were accessed.",
    },
    {
      label: "Opens",
      sortKey: "open",
      renderCell: (item) => item.open,
      type: "normal_cell",
      floatingInfo:
        "Represents the number of times the mobile apps were launched from campaign links.",
    },
    {
      label: "Installs",
      sortKey: "install",
      renderCell: (item) => item.install,
      type: "normal_cell",
      floatingInfo:
        "Indicates the number of app installations resulting from campaign links.",
    },
    {
      label: "Reinstalls",
      sortKey: "reinstall",
      renderCell: (item) => item.reinstall,
      type: "normal_cell",
      floatingInfo:
        "Indicates the number of app reinstallations resulting from campaign links.",
    },
    // Removed for now
    // {
    //   label: "Revenue",
    //   sortKey: "revenue",
    //   renderCell: (item) => item.revenue,
    //   type: "normal_cell",
    //   noSort: true,
    //   floatingInfo:
    //     "Represents the total revenue generated by the campaign, including in-app purchases and custom events.",
    // },
    {
      label: "Date",
      sortKey: "created_at",
      renderCell: (item) => item.date,
      type: "normal_cell",
      floatingInfo: "The campaign's creation date.",
    },
    {
      label: "",
      noSort: true,
      renderCell: () => <div>action</div>,
      action: (item) => {
        handleSelectedCampaign(item);
      },
      type: "action_cell",
    },
  ];

  const theme = useTheme({
    Table: `
    --data-table-library_grid-template-columns:200px repeat(4, minmax(110px,1fr)) minmax(150px,1fr) 80px !important;
    `,
    BaseCell: `
    &:nth-of-type(1) {
      left: 0px;
    }

    &:nth-of-type(2) {
      left: 150px;
    }
  `,

    HeaderRow: `
        width:100%;
        background:${lightThemeColors.menuBackground};
        border-radius:5px;
        overflow:hidden;
        `,

    HeaderCell: `
        font-size:16px;
        font-weight:700;
        color:${lightThemeColors.darkColorFaded};
        padding:14px;
       flex:inherit;
       -webkit-flex:inherit;

        :first-child{
            border-radius:5px 0 0 5px;
        }
        :last-child{
            border-radius:0 5px 5px 0;
        }
        div{
            flex:inherit;
        }
        `,

    Cell: `
      

        overflow:hidden;
        padding:14px;
        gap:20px;
        color:${lightThemeColors.darkColorFaded};
        font-size:16px;
        font-weight:${(props) => (props.lightText ? 500 : 700)};
        border-bottom:1px solid ${lightThemeColors.menuBackground};

        `,
  });

  const checkAlign = (alignStart) => {
    if (alignStart === true) {
      return "start";
    } else {
      return "center";
    }
  };

  const handleChangeSort = (sortKey) => {
    setPage(1);
    setSort({
      sortKey: sortKey,
      ascendent: !sort.ascendent,
    });
  };

  useEffect(() => {
    createNodesFromData();
  }, [campaigns]);

  return (
    <Container>
      <Tooltip
        style={{ zIndex: "100", background: lightThemeColors.primaryColor }}
        id={"audience-tooltip"}
        variant="info"
      />
      <LinksTableWrapper>
        <Table
          data={data}
          theme={theme}
          columns={COLUMNS}
          layout={{ custom: true }}
        >
          {(tableList) => (
            <>
              <Header>
                <HeaderRow>
                  {COLUMNS.map((column, index) => (
                    <HeaderCellCustom
                      key={column.label}
                      center={checkAlign(column.alignStart)}
                      background={column.background}
                      pinLeft={column.pinLeft}
                      sortIcon={
                        (column.type === "action_cell" ||
                          column.type === "tags_cell") && {
                          iconDefault: null,
                          iconUp: null,
                          iconDown: null,
                        }
                      }
                    >
                      {column.floatingInfo && (
                        <>
                          <InfoIcon
                            style={{ minWidth: "13px" }}
                            data-tooltip-id={"audience-tooltip"}
                            data-tooltip-content={column.floatingInfo}
                          />
                        </>
                      )}

                      {column.label}
                      <SortSwitch
                        noSort={column.noSort}
                        sortKey={column.sortKey}
                        sort={sort}
                        onClick={(e) => handleChangeSort(column.sortKey)}
                      />
                    </HeaderCellCustom>
                  ))}
                </HeaderRow>
              </Header>

              <Body>
                {tableList.map((item) => (
                  <Row
                    key={item.id}
                    item={item}
                    onClick={() => {
                      handleSelectedCampaign(item);
                    }}
                  >
                    {COLUMNS.map((column, index) => (
                      <DisplayCellBasedOnType
                        key={column.label + item.id}
                        value={column.renderCell(item)}
                        type={column.type}
                        pinLeft={column.pinLeft}
                        onClick={() => {
                          handleSelectedCampaign(item);
                        }}
                      />
                    ))}
                  </Row>
                ))}
              </Body>
            </>
          )}
        </Table>

        <PaginationWrapper>
          {totalPages > 1 ? (
            <>
              <PaginationBtnsWrapper>
                {/* Previous Button */}
                <PaginationBtn
                  selected={false}
                  type="button"
                  disabled={page === 1}
                  onClick={() => setPage(page - 1)}
                >
                  {"<"}
                </PaginationBtn>
                {page}

                {/* Next Button */}
                <PaginationBtn
                  selected={false}
                  type="button"
                  disabled={page === totalPages}
                  onClick={() => setPage(page + 1)}
                >
                  {">"}
                </PaginationBtn>
              </PaginationBtnsWrapper>
            </>
          ) : (
            <Separator></Separator> // Your "else" content
          )}
        </PaginationWrapper>
      </LinksTableWrapper>
    </Container>
  );
};

export default CampaignsLinksView;

const Separator = styled.div`
  height: 30px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 50%;
  height: 100%;
  width: 100%;
  position: relative;
  gap: 20px;
`;

const Line = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const LinksTableWrapper = styled.div`
  z-index: 0;
`;

const CustomCell = styled(Cell)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 12px 14px;
  gap: 20px;
  border-bottom: 1px solid ${lightThemeColors.menuBackground};
  display: flex;
  margin-left: auto;
  margin-right: auto;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: ${lightThemeColors.links.tableCellColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
`;

const LeftContentCell = styled(CustomCell)`
  justify-content: start;
  margin-left: 0;
  p {
    text-align: start;
  }
`;

const ColoredCell = styled(CustomCell)`
  p {
    color: ${lightThemeColors.primaryColor};
  }
`;

const HeaderCellCustom = styled(HeaderCell)`
  background: ${(props) =>
    props.background ? props.background : "inherit"} !important;
  ${(props) =>
    props.center == "center"
      ? `
div{

display:flex;
gap:5px;
align-items:center;
    margin-left:auto;
    margin-right:auto;
    font-size:14px;
}
`
      : `
div{

    display:flex;
    gap:5px;
    align-items:center;
    margin-right:auto;
    font-size:14px;
}
`}
`;
const ActionCell = styled(CustomCell)`
  justify-content: center;
  display: flex;
  cursor: pointer;
`;

const SortSwitchElement = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const PaginationWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  justify-content: center;
  span {
    font-size: 16px;
    fonst-weight: 500;
    color: ${lightThemeColors.darkColor};
  }
`;
const PaginationBtn = styled.button`
  font-size: 16px;
  fonst-weight: 500;
  color: ${(props) =>
    props.selected
      ? lightThemeColors.darkColor
      : lightThemeColors.darkColorFaded30};
  background: none;
  border: none;
  cursor: pointer;
  width: 20px;
  &:disabled {
    opacity: 0.3;
  }
`;
const PaginationBtnsWrapper = styled.div`
  display: flex;
  border: 1px solid ${lightThemeColors.darkColorFaded30};
  border-radius: 5px;
  padding: 5px;
  gap: 5px;
`;

const TableExtraHeader = styled.div`
  display: flex;
  background: ${lightThemeColors.menuBackground};
  padding: 9px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid ${lightThemeColors.sidebar.projectsBg};
  margin-left: 430px;

  p {
    color: ${lightThemeColors.darkColorFaded60};
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: left;
  }
`;
