import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { ReactComponent as Grovs } from "../../assets/icons/linkTypeAdsIcons/Grovs.svg";
import { ReactComponent as GoogleAdIcon } from "../../assets/icons/linkTypeAdsIcons/Google.svg";
import { ReactComponent as MetaAdIcon } from "../../assets/icons/linkTypeAdsIcons/MetaPlatforms.svg";
import { ReactComponent as LinkedinAdIcon } from "../../assets/icons/linkTypeAdsIcons/LinkedIn.svg";
import { ReactComponent as TikTokAdIcon } from "../../assets/icons/linkTypeAdsIcons/TikTok.svg";
import GeneratedLink from "./GeneratedLink";
import SecondaryButton from "../Generic/SecondaryButton";
import { copyToClipboard } from "../../helper/copy";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CopyIcon } from "../../assets/icons/generic/CopyIcon.svg";
import {
  GOOGLE,
  LINKEDIN,
  META,
  TIKTOK,
} from "../../constants/OptionsConstants";

const GeneratedAdsPlatformLinkPreview = ({ createdLink }) => {
  const [linkType, setLinkType] = useState(GOOGLE);

  const verifyLinkType = (link) => {
    if (!link) {
      return;
    }
    switch (link.ads_platform) {
      case GOOGLE:
        setLinkType(GOOGLE);
        break;
      case META:
        setLinkType(META);
        break;
      case LINKEDIN:
        setLinkType(LINKEDIN);
        break;
      case TIKTOK:
        setLinkType(TIKTOK);
        break;
      default:
        break;
    }
  };

  const displayHeader = (linkType) => {
    let header = <></>;
    switch (linkType) {
      case GOOGLE:
        header = (
          <Line style={{ gap: "10px" }}>
            <GoogleAdIcon />
            <AdPlatformTitle>Google Ads Link</AdPlatformTitle>
          </Line>
        );
        break;
      case META:
        header = (
          <Line style={{ gap: "10px" }}>
            <MetaAdIcon />
            <AdPlatformTitle>Meta Ads Link</AdPlatformTitle>
          </Line>
        );
        break;
      case LINKEDIN:
        header = (
          <Line style={{ gap: "10px" }}>
            <LinkedinAdIcon />
            <AdPlatformTitle>LinkedIn Ads Link</AdPlatformTitle>
          </Line>
        );
        break;
      case TIKTOK:
        header = (
          <Line style={{ gap: "10px" }}>
            <TikTokAdIcon />
            <AdPlatformTitle>TikTok Ads Link</AdPlatformTitle>
          </Line>
        );
        break;

      default:
        break;
    }
    return header;
  };

  const displayHowToUse = (linkType) => {
    let header = <></>;
    switch (linkType) {
      case GOOGLE:
        header = (
          <InfoText>
            <span>How to Use:</span> Paste the generated link into the 
            <Highlighted>Final URL</Highlighted> field when setting up your
            Google Ads campaigns, including search, display, or video ads.
            <br />
            <br />
            For detailed instructions on creating a campaign and adding your
            link, refer to{" "}
            <a
              href="https://support.google.com/google-ads/answer/6324971?hl=en"
              target="_blank"
              rel="noopener noreferrer"
            >
              Google's official guide
            </a>
            .
          </InfoText>
        );
        break;
      case META:
        header = (
          <InfoText>
            <span>How to Use:</span> Insert the generated link into the 
            <Highlighted>Website URL </Highlighted> field during the ad creation
            process on Facebook or Instagram.
            <br />
            <br />
            For a step-by-step guide on creating ads and adding your link, see{" "}
            <a
              href="https://www.facebook.com/business/ads"
              target="_blank"
              rel="noopener noreferrer"
            >
              Meta's official instructions.
            </a>
          </InfoText>
        );
        break;
      case LINKEDIN:
        header = (
          <InfoText>
            <span>How to Use:</span> Place the generated link into the
            <Highlighted>Destination URL </Highlighted> field when creating your
            LinkedIn ad campaigns.
            <br />
            <br />
            For comprehensive guidance on setting up LinkedIn ads and
            incorporating your link, consult{" "}
            <a
              href="https://business.linkedin.com/marketing-solutions/how-to-advertise-on-linkedin"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn's official resources.
            </a>
          </InfoText>
        );
        break;

      case TIKTOK:
        header = (
          <InfoText>
            <span>How to Use:</span> Paste the generated link into the
            <Highlighted>the Website Link field</Highlighted> when setting up
            your TikTok ads.
            <br />
            For detailed steps on creating TikTok ads and adding your link,
            refer to{" "}
            <a
              href="https://ads.tiktok.com/help/article/ad-set-up"
              target="_blank"
              rel="noopener noreferrer"
            >
              TikTok's official guide.
            </a>
          </InfoText>
        );
        break;

      default:
        break;
    }
    return header;
  };

  useEffect(() => {
    verifyLinkType(createdLink);
  }, [createdLink]);

  return (
    <ConfigList>
      {displayHeader(linkType)}
      {displayHowToUse(linkType)}
      <Separator />
      <AdPlatformTitle>Generated Link</AdPlatformTitle>
      <Line style={{ alignItems: "end", marginTop: "-10px" }}>
        <GeneratedLink
          style={{ maxWidth: "500px" }}
          generatedLink={createdLink?.access_path}
        />
        <SecondaryButton
          icon={<CopyIcon />}
          text={"Copy Link"}
          onClick={() => copyToClipboard(createdLink?.access_path)}
        />
      </Line>
      <Separator />
      <InfoText>
        Our platform will monitor and report the number of times each link is
        viewed, opened, or results in an app installation, providing you with
        valuable insights into your campaign's performance.
      </InfoText>
    </ConfigList>
  );
};

export default GeneratedAdsPlatformLinkPreview;

const ConfigList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  position: relative;
  height: 100%;
  padding: 0 40px 30px 40px;
`;

const Line = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
  margin: 10px 0;
`;
const AdPlatformTitle = styled.h3`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: ${lightThemeColors.darkColor};
`;
const InfoText = styled.p`
  color: ${lightThemeColors.darkColorFaded30};
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  span {
    font-weight: 600;
    color: ${lightThemeColors.darkColor};
  }
  a {
    color: ${lightThemeColors.primaryColor};
    text-decoration: underline;
  }
`;

const Highlighted = styled.span`
  color: ${lightThemeColors.primaryColor} !important;
`;
