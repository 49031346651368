import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../../components/configurationComponents/ConfigurationSectionHeader";
import DefaultUrlSetup from "./DefaultUrlSetup";
import AndroidSetup from "./AndroidSetup";
import IosSetup from "./IosSetup";
import DesktopSetup from "./DesktopSetup";
import { ReactComponent as RedirectIcon } from "../../assets/icons/generic/redirectIcon.svg";
import { useInstanceContext } from "../../context/useInstanceContext";
import { useConfigurationContext } from "../../context/useConfigurationContext";
import {
  createSearchParams,
  useNavigate,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import {
  showErrorNotification,
  showErrorNotificationWithLink,
  showGenericError,
} from "../../helper/Notifications";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import { ANDROID, DESKTOP, IOS, PHONE } from "../../constants/OptionsConstants";

import { isCustomUrlValid } from "../../helper/ValidatorsHelper";

const RedirectConfig = () => {
  const {
    selectedProject,
    selectedInstance,
    instanceConfig,
    setProjectDesktopConfiguration,
    getProjectConfiguration,

  } = useInstanceContext();

  const {
    projectRedirectsConfig,
    setProjectDefaultRedirect,
    setProjectRedirectsConfig,
    setProjectRedirect,
  } = useConfigurationContext();

  const { setLoading, projectType } = useOutletContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [expanded, setExpanded] = useState(false);
  const [subtitle, setSubtitle] = useState(null);
  const [defaultUrl, setDefaultUrl] = useState(null);

  const [androidConfig, setAndroidConfig] = useState(null);
  const [iosConfig, setIosConfig] = useState(null);
  const [desktopConfig, setDesktopConfig] = useState(null);

  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [androidRedirectConfig, setAndroidRedirectConfig] = useState(null);
  const [iOSRedirectConfig, setIOSRedirectConfig] = useState(null);
  const [desktopRedirectConfig, setDesktopRedirectConfig] = useState(null);

  const [androidApp, setAndroidApp] = useState(null);
  const [androidStore, setAndroidStore] = useState(true);
  const [androidCustomUrl, setAndroidCustomUrl] = useState("");

  const [iosApp, setIosApp] = useState(null);
  const [iosStore, setIosStore] = useState(false);
  const [iosCustomUrl, setIosCustomUrl] = useState("");

  const [desktopGeneratedPage, setDesktopGeneratedPage] = useState(true);
  const [desktopCustomUrl, setDesktopCustomUrl] = useState("");



  const handleExpand = () => {
    setExpanded(!expanded);
    setSubtitle(null);
  };

  const expandIfTour = () => {
    if (searchParams.get("redirectTour") === "true") {
      setExpanded(true);
      setSearchParams((params) => {
        params.delete("redirectTour")
        return params
      })
    }
  }
  const handleSetDefaultRedirect = () => {

    setLoading(true);
    setProjectDefaultRedirect(
      selectedProject.id,
      defaultUrl,
      (response) => {
        setProjectRedirectsConfig(response.data.redirect_config);
        setLoading(false);
      },
      () => {
        showGenericError();
        setLoading(false);
      }
    );

  };

  const handleSave = () => {
    let androidHaveChanges = androidRedirectConfigHaveChanges();
    let iosHaveChanges = iosRedirectConfigHaveChanges();

    let defaultUrlHaveChanged = isDefaultUrlChanged();

    let androidValidConfig = isAndroidRedirectConfigValid();
    let iosValidConfig = isIosRedirectConfigValid();
    let defaultUrlValid = isDefaultUrlValid();

    let desktopHaveChanges = isDesktopConfigChanged();
    let desktopUrlValid = isDesktopUrlValid();



    if (defaultUrl != "") {

      if (defaultUrlHaveChanged && defaultUrlValid) {
        handleSetDefaultRedirect();
      }
      if (androidHaveChanges && androidValidConfig) {
        handleSaveAndroid();
      }
      if (iosHaveChanges && iosValidConfig) {
        handleSaveIos();
      }
      if (desktopHaveChanges && desktopUrlValid) {
        handleSaveDesktop();
      }
    } else {
      showErrorNotification("You need to fill in a fallback URL before saving the configuration.")
    }
  };

  const handleSaveAndroid = () => {


    if (androidApp) {
      if (androidStore) {
        handleSetAndroidRedirect(true, null, true);
      } else {
        handleSetAndroidRedirect(false, androidCustomUrl, true);
      }
    }
    else {
      handleSetAndroidRedirect(false, androidCustomUrl, false);
    };
  }


  const handleGetProjectConfig = () => {
    // setLoading(true);
    getProjectConfiguration(
      selectedInstance.id,
      (response) => {
        // setLoading(false);
      },
      () => {
        showGenericError();
        // setLoading(false);
      }
    );
  };

  const handleSetAndroidRedirect = (appStore, fallbackUrl, enabled) => {
    setProjectRedirect(
      selectedProject.id,
      ANDROID,
      PHONE,
      appStore,
      fallbackUrl,
      enabled,
      (response) => {
        setProjectRedirectsConfig(response.data.config);
      },
      () => {
        showGenericError();
      }
    );
  };

  const handleSetIosRedirect = (appStore, fallbackUrl, enabled) => {
    setProjectRedirect(
      selectedProject.id,
      IOS,
      PHONE,
      appStore,
      fallbackUrl,
      enabled,
      (response) => {
        setProjectRedirectsConfig(response.data.config);
      },
      () => {
        showGenericError();
      }
    );
  };

  const handleSetDesktopRedirect = (appStore, fallbackUrl, enabled) => {
    setProjectRedirect(
      selectedProject.id,
      DESKTOP,
      DESKTOP,
      appStore,
      fallbackUrl,
      enabled,
      (response) => {
        setProjectRedirectsConfig(response.data.config);
      },
      () => {
        showGenericError();
      }
    );
  };

  const loadInitialAndroidRedirectConfig = (redirectConfig) => {

    if (redirectConfig) {
      setAndroidStore(redirectConfig.appstore);
      setAndroidCustomUrl(redirectConfig.fallback_url || "");
    } else {
      setAndroidStore(false);
      setAndroidCustomUrl("");
    }
  };

  const loadInitialIosRedirectConfig = (redirectConfig) => {
    if (redirectConfig) {
      setIosStore(redirectConfig.appstore);
      setIosCustomUrl(redirectConfig.fallback_url || "");
    } else {
      setIosStore(true);
      setIosCustomUrl("");
    }
  };

  const loadInitialDesktopRedirectConfig = (redirectConfig) => {
    if (redirectConfig) {
      setDesktopGeneratedPage(redirectConfig.appstore);
      setDesktopCustomUrl(redirectConfig.fallback_url || "");
    } else {
      setDesktopGeneratedPage(false);
      setDesktopCustomUrl("");
    }
  }


  const isAndroidRedirectConfigValid = () => {
    let valid = true;
    if (!androidApp) {
      if (androidRedirectConfig?.phone?.fallback_url) {
        if (
          isCustomUrlValid(androidCustomUrl) ||
          androidCustomUrl == ""
        ) {
          valid = true;
        } else {
          valid = false;
        }
      }
      else {
        if (androidCustomUrl) {
          if (isCustomUrlValid(androidCustomUrl)) {
            valid = true;
          } else {
            valid = false;
          }

        } else {
          if (androidCustomUrl == "" || androidCustomUrl == null) {
            valid = true;
          } else {
            valid = false;
          }
        }
      }
    } else {
      if (androidStore) {
        valid = true;
      }
      else {
        if (
          androidCustomUrl != null &&
          androidCustomUrl.length > 0 &&
          isCustomUrlValid(androidCustomUrl)
        ) {
          valid = true;
        } else {
          valid = false;
        }
      }
    }
    return valid;
  };


  const isIosRedirectConfigValid = () => {
    let valid = true;
    if (!iosApp) {
      if (iOSRedirectConfig?.phone?.fallback_url) {
        if (
          isCustomUrlValid(iosCustomUrl) ||
          iosCustomUrl == ""
        ) {
          valid = true;
        } else {
          valid = false;
        }
      }
      else {
        if (iosCustomUrl) {
          if (isCustomUrlValid(iosCustomUrl)) {
            valid = true;
          } else {
            valid = false;
          }
        } else {
          if (iosCustomUrl == "" || iosCustomUrl == null) {
            valid = true;
          } else {
            valid = false;
          }
        }
      }
    } else {
      if (iosStore) {
        valid = true;
      }
      else {
        if (
          iosCustomUrl != null &&
          iosCustomUrl.length > 0 &&
          isCustomUrlValid(iosCustomUrl)
        ) {
          valid = true;
        } else {
          valid = false;
        }
      }
    }

    return valid;
  };

  const isDefaultUrlValid = () => {
    let valid = true;

    if (
      defaultUrl === "" ||
      (defaultUrl && isCustomUrlValid(defaultUrl))

    ) {
      valid = true;
    } else {
      valid = false
    }


    return valid;
  };

  const androidRedirectConfigHaveChanges = () => {
    let haveChanged = false;
    if (!androidRedirectConfig) {
      return
    }
    if (androidRedirectConfig?.phone) {
      if (androidRedirectConfig?.phone?.fallback_url) {
        if (
          androidApp !== androidRedirectConfig?.phone?.enabled ||
          androidStore !== androidRedirectConfig?.phone?.appstore ||
          androidCustomUrl !== androidRedirectConfig?.phone?.fallback_url
        ) {
          haveChanged = true;
        }
      }
      else
        if (
          androidApp !== androidRedirectConfig?.phone?.enabled ||
          androidStore !== androidRedirectConfig?.phone?.appstore ||
          androidCustomUrl !== ""
        ) {
          haveChanged = true;
        }
    }
    else {
      if (
        androidApp !== false ||
        androidStore !== false ||
        androidCustomUrl !== ""
      ) {
        haveChanged = true;
      }
    }

    return haveChanged;
  };

  const iosRedirectConfigHaveChanges = () => {
    let haveChanged = false;

    if (!iOSRedirectConfig) {
      return false
    }

    if (iOSRedirectConfig?.phone) {
      if (iOSRedirectConfig?.phone?.fallback_url) {
        if (
          iosApp !== iOSRedirectConfig?.phone?.enabled ||
          iosStore !== iOSRedirectConfig?.phone?.appstore ||
          iosCustomUrl !== iOSRedirectConfig?.phone?.fallback_url
        ) {
          haveChanged = true;
        }
      }
      else
        if (
          iosApp !== iOSRedirectConfig?.phone?.enabled ||
          iosStore !== iOSRedirectConfig?.phone?.appstore ||
          iosCustomUrl !== ""
        ) {
          haveChanged = true;
        }
    }

    else {
      if (
        iosApp !== false ||
        iosStore !== true ||
        iosCustomUrl !== ""
      ) {
        haveChanged = true;
      }
    }

    return haveChanged;
  };

  const isDefaultUrlChanged = () => {
    let haveChanged = false;

    if (!projectRedirectsConfig) {
      return false
    }

    if (projectRedirectsConfig.default_fallback) {
      if (defaultUrl !== projectRedirectsConfig?.default_fallback) {
        haveChanged = true;

      }
    }
    else {
      if (defaultUrl !== "") {
        haveChanged = true;
      }
    }
    return haveChanged;
  };

  const isDesktopConfigChanged = () => {
    let haveChanged = false;

    if (!desktopRedirectConfig) {
      return false
    }

    if (desktopRedirectConfig.all) {
      if (desktopGeneratedPage) {
        if (desktopGeneratedPage !== desktopRedirectConfig?.all?.appstore) {
          haveChanged = true
        }
      } else {
        if (desktopRedirectConfig?.all?.fallback_url !== desktopCustomUrl) {
          haveChanged = true
        }
      }
    } else {
      if (desktopGeneratedPage !== true || desktopCustomUrl !== "") {
        haveChanged = true
      }
    }

    return haveChanged;
  };

  const isDesktopUrlValid = () => {
    let valid = true;
    if (desktopGeneratedPage) {
      valid = true
    } else
      if (
        desktopCustomUrl != null &&
        desktopCustomUrl.length > 0 &&
        isCustomUrlValid(desktopCustomUrl)
      ) {
        valid = true;
      } else {
        valid = false;
      }

    return valid;
  };

  const handleSaveIos = () => {
    if (iosApp) {
      if (iosStore) {
        handleSetIosRedirect(true, null, true);
      } else {
        handleSetIosRedirect(false, iosCustomUrl, true);
      }
    } else {
      handleSetIosRedirect(false, iosCustomUrl, false);
    }
  };


  const handleSaveDesktop = () => {
    if (desktopGeneratedPage) {
      handleSetDesktopRedirect(true, null, true);
    }
    else {
      if (desktopCustomUrl !== "") {
        handleSetDesktopRedirect(false, desktopCustomUrl, true);
      } else {
        handleSetDesktopRedirect(false, null, true);
      }
    }
  };

  const handleCheckAndroidAppChange = (value) => {
    if (!androidConfig?.configuration) {
      showErrorNotificationWithLink(
        "You have to integrate Android app first, click here and integrate app",
        () => {
          navigate({
            pathname: "/developers",
            search: createSearchParams(searchParams).toString(),
          });
        }
      );
      return;
    }

    setAndroidApp(value);
    setAndroidStore(true)
  };

  const handleCheckIosAppChange = (value) => {
    if (!iosConfig?.configuration) {
      showErrorNotificationWithLink(
        "You have to integrate iOS app first, click here and integrate app",
        () => {
          navigate({
            pathname: "/developers",
            search: createSearchParams(searchParams).toString(),
          });
        }
      );
      return;
    }
    setIosApp(value);
    setIosStore(true)

  };

  const handleExpandRedirectFromParams = () => {
    if (searchParams.get("expandRedirect")) {
      handleExpand();

      setSearchParams((prev) => {
        prev.delete("expandRedirect")
        return prev
      })

    }
  }

  useEffect(() => {
    if (!instanceConfig) {
      return;
    }
    let androidConfigFound = instanceConfig.find(
      (config) => config.platform === ANDROID
    );
    let iosConfigFound = instanceConfig.find(
      (config) => config.platform === IOS
    );
    let desktopConfigFound = instanceConfig.find(
      (config) => config.platform === DESKTOP
    );

    if (instanceConfig.length > 0 && androidConfigFound) {
      setAndroidConfig(androidConfigFound);
    } else {
      setAndroidConfig(null);
    }

    if (instanceConfig.length > 0 && iosConfigFound) {
      setIosConfig(iosConfigFound);
    } else {
      setIosConfig(null);
    }

    if (instanceConfig.length > 0 && desktopConfigFound) {
      setDesktopConfig(desktopConfigFound);
    } else {
      setDesktopConfig(null);
    }

  }, [instanceConfig]);

  useEffect(() => {
    if (!projectRedirectsConfig) {
      return;
    }
    setDefaultUrl(projectRedirectsConfig.default_fallback || "");

    setAndroidRedirectConfig(projectRedirectsConfig.android);
    setIOSRedirectConfig(projectRedirectsConfig.ios);
    setDesktopRedirectConfig(projectRedirectsConfig.desktop);

    loadInitialAndroidRedirectConfig(projectRedirectsConfig.android.phone);
    loadInitialIosRedirectConfig(projectRedirectsConfig.ios.phone);
    loadInitialDesktopRedirectConfig(projectRedirectsConfig.desktop.all);

  }, [projectRedirectsConfig]);

  useEffect(() => {
    if (androidRedirectConfig?.phone) {
      setAndroidApp(androidRedirectConfig.phone.enabled);
    } else {
      setAndroidApp(false);
    }
  }, [androidRedirectConfig]);

  useEffect(() => {
    if (iOSRedirectConfig?.phone) {
      setIosApp(iOSRedirectConfig.phone.enabled);
    } else {
      setIosApp(false);
    }
  }, [iOSRedirectConfig]);

  useEffect(() => {
    if (desktopRedirectConfig?.all) {
      setDesktopGeneratedPage(desktopRedirectConfig.all.appstore);
      setDesktopCustomUrl(desktopRedirectConfig.all.fallback_url || "");
    } else {
      setDesktopGeneratedPage(true);
      setDesktopCustomUrl("")
    }
  }, [desktopRedirectConfig]);

  useEffect(() => {
    let defaultUrlValid = isDefaultUrlValid();
    let defaultUrlChanges = isDefaultUrlChanged();

    let androidValidConfig = isAndroidRedirectConfigValid();
    let androidHaveChanges = androidRedirectConfigHaveChanges();

    let iosValidConfig = isIosRedirectConfigValid();
    let iosHaveChanges = iosRedirectConfigHaveChanges();

    let desktopConfigChanges = isDesktopConfigChanged();
    let desktopUrlValid = isDesktopUrlValid();



    if (defaultUrlValid) {
      if (
        (androidValidConfig === true && androidHaveChanges === true) ||
        (iosValidConfig === true && iosHaveChanges === true) ||
        (desktopUrlValid === true && desktopConfigChanges === true) ||
        (defaultUrlValid === true && defaultUrlChanges === true)
      ) {
        setIsSaveDisabled(false);
      } else {
        setIsSaveDisabled(true);
      }
    } else {
      setIsSaveDisabled(true);
    }

  }, [
    defaultUrl,
    androidApp,
    androidCustomUrl,
    androidStore,
    androidRedirectConfig,
    iosApp,
    iosCustomUrl,
    iosStore,
    iOSRedirectConfig,
    desktopCustomUrl,
    desktopGeneratedPage,
    desktopRedirectConfig
  ]);

  useEffect(() => {
    handleExpandRedirectFromParams()
  }, [])
  useEffect(() => {
    expandIfTour()
  }, [searchParams])

  return (
    <Container>
      <ConfigurationSectionHeader
        id={"redirectSectionHeader"}
        title={"Redirect"}
        subtitle={subtitle}
        handleExpand={handleExpand}
        expanded={expanded}
        showValidation
        isValid={
          projectRedirectsConfig &&
          projectRedirectsConfig?.default_fallback &&
          isCustomUrlValid(projectRedirectsConfig?.default_fallback)
        }
        unsavedChanges={isSaveDisabled === false}
        icon={<RedirectIcon />}
        customButton={
          !isSaveDisabled && (
            <PrimaryButton
              disabled={isSaveDisabled}
              bgVariant={lightThemeColors.background}
              styled={{ color: lightThemeColors.primaryColor }}
              text={"Save"}
              onClick={(e) => handleSave()}
            />
          )
        }
      />

      {expanded && (
        <ContentContainer id="redirectSection">
          <DefaultUrlSetup
            setSubtitle={setSubtitle}
            defaultUrl={defaultUrl}
            setDefaultUrl={setDefaultUrl}
          />
          <Separator />
          <AndroidSetup
            setSubtitle={setSubtitle}
            customUrl={androidCustomUrl}
            setCustomUrl={setAndroidCustomUrl}
            androidStore={androidStore}
            setAndroidStore={setAndroidStore}
            androidApp={androidApp}
            setAndroidApp={handleCheckAndroidAppChange}
          />
          <Separator />
          <IosSetup
            setSubtitle={setSubtitle}
            customUrl={iosCustomUrl}
            setCustomUrl={setIosCustomUrl}
            iosStore={iosStore}
            setIosStore={setIosStore}
            iosApp={iosApp}
            setIosApp={handleCheckIosAppChange}
          />
          <Separator />
          <DesktopSetup
            desktopGeneratedPage={desktopGeneratedPage}
            setDesktopGeneratedPage={setDesktopGeneratedPage}
            desktopCustomUrl={desktopCustomUrl}
            setDesktopCustomUrl={setDesktopCustomUrl}
            setSubtitle={setSubtitle}
          />
        </ContentContainer>
      )}
    </Container>
  );
};

export default RedirectConfig;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 30px;
  padding-top: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  position: relative;
`;

const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
  margin: 30px 0;
`;
