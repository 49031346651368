import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as ValidIcon } from "../../assets/icons/generic/validCircle.svg";

const InputFieldWithValidation = ({
  title,
  subtitle,
  label,
  inputValue,
  labelAction,
  handleInputChange,
  inputName,
  valid,
  style,
  placeholder,
  noValidation,
  type,
  readOnly,
  inputContainerStyle,
  customButton,
  inputCustomStyle,
  inputWrapperStyle,
  important,
}) => {
  return (
    <Container style={style}>
      {title && <h1>{title}</h1>}
      {subtitle && <h2>{subtitle}</h2>}
      {important && <h3>{important}</h3>}
      <Line style={inputWrapperStyle}>
        <InputContainer readOnly={readOnly} style={inputContainerStyle}>
          <input
            style={inputCustomStyle}
            readOnly={readOnly}
            value={inputValue}
            onChange={handleInputChange}
            name={inputName}
            placeholder={placeholder}
            type={type}
          />

          {!noValidation ? (
            valid ? (
              <ValidIcon style={{ color: lightThemeColors.validGreen }} />
            ) : (
              <ValidIcon
                style={{ color: lightThemeColors.darkColorFullFaded }}
              />
            )
          ) : (
            ""
          )}
        </InputContainer>
        {customButton && customButton}
      </Line>
      {label && <label onClick={labelAction}>{label}</label>}
    </Container>
  );
};

export default InputFieldWithValidation;
const Container = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 5px;
  }
  h2 {
    color: ${lightThemeColors.inputSubtitleColor};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  h3 {
    color: ${lightThemeColors.quotaColor};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  label {
    cursor: pointer;
    color: ${lightThemeColors.inputLabelColor};
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 15px;
  border-radius: 5px;
  border: 1px solid ${lightThemeColors.darkColorFullFaded};
  background: ${(props) =>
    props.readOnly
      ? lightThemeColors.menuBackground
      : lightThemeColors.background};
  gap: 5px;

  input {
    width: 100%;
    outline: none;
    border: none;
    color: ${lightThemeColors.darkColorFaded};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    &:read-only {
      background: ${lightThemeColors.menuBackground};
    }
  }

  :: placeholder {
    font-size: 15px;
    opacity: 0.7;
  }

  svg {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 20px;
    max-width: 19px;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
