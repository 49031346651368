import { DELETE, PATCH, POST } from "../API";
let getSource;
export const createNewCampaignAPICall = (
  projectId,
  formData,
  onSuccess,
  onError
) => {
  POST(
    process.env.REACT_APP_API_PATH + `/projects/${projectId}/campaigns/create`,
    formData,
    onSuccess,
    onError
  );
};
export const updateCampaignAPICall = (
  projectId,
  campaignId,
  formData,
  onSuccess,
  onError
) => {
  PATCH(
    process.env.REACT_APP_API_PATH +
      `/projects/${projectId}/campaigns/${campaignId}/update`,
    formData,
    onSuccess,
    onError
  );
};
export const archiveCampaignAPICall = (
  projectId,
  campaignId,
  onSuccess,
  onError
) => {
  DELETE(
    process.env.REACT_APP_API_PATH +
      `/projects/${projectId}/campaigns/${campaignId}`,
    onSuccess,
    onError
  );
};

export const getCampaignsAPICall = (
  projectId,
  formData,
  onSuccess,
  onError
) => {
  POST(
    process.env.REACT_APP_API_PATH + `/projects/${projectId}/campaigns`,
    formData,
    onSuccess,
    onError,
    true
  );
};

export const getCampaignMetricsAPICall = (
  projectId,
  formData,
  onSuccess,
  onError
) => {
  POST(
    process.env.REACT_APP_API_PATH +
      `/projects/${projectId}/campaigns/metrics_for_overview`,
    formData,
    onSuccess,
    onError,
    true,
    getSource
  );
};
