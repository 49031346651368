import React, { useState } from 'react'
import styled from 'styled-components'
import { ReactComponent as UserIcon } from "../../assets/icons/generic/UserIcon.svg"
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom'
import { lightThemeColors } from '../../assets/styles/colors'
import { useUserContext } from '../../context/useUserContext'
const AccountButton = () => {
    const { userRef } = useUserContext();
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();

    const goToAccount = () => {
        navigate({
            pathname: "/account",
            search: createSearchParams(searchParams).toString(),
        });
    }

    return (
        <UserButton onClick={() => goToAccount()}>
            <UserIcon />
            {userRef.current?.name}
        </UserButton>
    )
}

export default AccountButton

const UserButton = styled.button`
display:flex;
align-items:center;
gap:10px;
border:none;
background:none;
cursor:pointer;
color:${lightThemeColors.darkColorFaded};
font-size: 16px;
font-weight: 500;
line-height: 24px;
text-align: left;


`