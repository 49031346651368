import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import TestProdHeader from "../../components/Generic/TestProdHeader";
import { useOutletContext } from "react-router-dom";
import {
  ACTIVE,
  GOOGLE,
  LINKEDIN,
  META,
  QUICK_LINK,
  TIKTOK,
} from "../../constants/OptionsConstants";

import { useLinkContext } from "../../context/useLinkContext";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";

import { useEventContext } from "../../context/useEventContext";
import moment from "moment";
import EditLinkModal from "../../components/linksComponents/EditLinkModal";
import { AnimatePresence } from "framer-motion";
import LinksPageContent from "../linksPage/LinksPageContent";
import CampaignsLinksEmptyState from "./CampaignsLinksEmptyState";

const CampaignLinksContent = ({ campaign }) => {
  const initialSort = {
    sortKey: "updated_at",
    ascendent: false,
  };

  const { selectedProject } = useInstanceContext();
  const { getLinks, linksRef, setLinks, links, shouldRefreshLinksRef } =
    useLinkContext();
  const {
    getEventsForSearchParams,
    getEventsSortedByParams,
    setEvents,
    events,
    eventsRef,
  } = useEventContext();

  const { setLoading } = useOutletContext();
  const today = new Date();
  const initialDateStart = moment(today)
    .subtract(29, "day")
    .startOf("day")
    .utc();
  const initialDateEnd = moment(today).endOf("day").utc();

  const [editLinkModalOpen, setEditLinkModalOpen] = useState(false);
  const [linksType, setLinksType] = useState(ACTIVE);
  const [searchInputValue, setSearchInputvalue] = useState("");
  const [startDate, setStartDate] = useState(new Date(initialDateStart));
  const [endDate, setEndDate] = useState(new Date(initialDateEnd));
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sort, setSort] = useState(initialSort);
  const [selectedLinkForEdit, setSelectedLinkForEdit] = useState(null);
  const [adsFilter, setAdsFilter] = useState(null);

  const [linksWithMetrics, setLinksWithMetrics] = useState([]);

  const adsFilterOptions = [
    { label: "Quick links", value: QUICK_LINK },
    { label: "Google", value: GOOGLE },
    { label: "Meta ", value: META },
    { label: "LinkedIn", value: LINKEDIN },
    { label: "TikTop", value: TIKTOK },
    { label: "All platforms", value: null },
  ];



  const handleGetLinks = () => {
    var dataObject = {
      active: linksType === ACTIVE,
      sdk: false,
      ascendent: sort.ascendent,
      page: page,
      start_date: moment(startDate).toISOString(),
      sort_by: sort.sortKey,
      campaign_id: campaign.id,
    };

    if (adsFilter != null) {
      dataObject.ads_platform = adsFilter;
    }

    if (searchInputValue != "") {
      dataObject.term = searchInputValue;
    }

    if (endDate) {
      dataObject.end_date = moment(endDate).toISOString();
    }

    setLoading(true);
    getLinks(
      selectedProject.id,
      dataObject,
      (response) => {
        linksRef.current = response.data.links;
        setLinks(linksRef.current);
        setTotalPages(response.data.total_pages);
        setLoading(false);
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleGetEventsForParams = () => {
    var dataObject = {
      active: linksType === ACTIVE,
      sdk: false,
      ascendent: sort.ascendent,
      page: page,
      start_date: moment(startDate).toISOString(),
      sort_by: sort.sortKey,
      campaign_id: campaign.id,
    };

    if (adsFilter != null) {
      dataObject.ads_platform = adsFilter;
    }

    if (searchInputValue != "") {
      dataObject.term = searchInputValue;
    }

    if (endDate) {
      dataObject.end_date = moment(endDate).toISOString();
    }

    getEventsForSearchParams(
      selectedProject.id,
      dataObject,
      (response) => {
        const ev = response.data.metrics;
        eventsRef.current = ev;
        setEvents(eventsRef.current);
        setLoading(false);
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleGetSortedEvents = () => {
    let data = {
      active: linksType === ACTIVE,
      sdk: false,
      ascendent: sort.ascendent,
      page: page,
      start_date: moment(startDate).toISOString(),
      event_type: sort.sortKey,
      campaign_id: campaign.id,
    };

    if (adsFilter != null) {
      data.ads_platform = adsFilter;
    }

    if (searchInputValue != "") {
      data.term = searchInputValue;
    }

    if (endDate) {
      data.end_date = moment(endDate).toISOString();
    }

    getEventsSortedByParams(
      selectedProject.id,
      data,
      (response) => {
        setLinksWithMetrics(response.data.result);
        setPage(response.data.page);
        setTotalPages(response.data.total_pages);
      },
      (error) => {
        showGenericError();
      }
    );
  };

  const handleEventsForSearchParamsResponse = (links, events) => {
    if (!links) {
      return;
    }

    let mapped = links.map((element) => {
      if (element.id in events) {
        return {
          link: element,
          metrics: events[element.id],
        };
      } else {
        return {
          link: element,
          metrics: null,
        };
      }
    });
    return mapped;
  };

  const handleLinksAndEvents = () => {
    if (sort.sortKey === "name" || sort.sortKey === "updated_at") {
      handleGetLinks();
      handleGetEventsForParams();
    } else {
      handleGetSortedEvents();
    }
  };

  const handleSearchInputValue = (inputValue) => {
    setSearchInputvalue(inputValue);
  };

  const handleEditLink = (link) => {
    setSelectedLinkForEdit(link);
    setEditLinkModalOpen(true);
  };

  const handleCloseEditLinkModal = () => {
    setEditLinkModalOpen(false);
    setSelectedLinkForEdit(null);
  };

  useEffect(() => {
    if (!selectedProject) {
      return;
    }
    handleLinksAndEvents();
  }, [
    selectedProject,
    startDate,
    endDate,
    linksType,
    page,
    searchInputValue,
    sort,
    adsFilter,
  ]);

  useEffect(() => {
    const mapped = handleEventsForSearchParamsResponse(
      linksRef.current,
      eventsRef.current
    );
    setLinksWithMetrics(mapped);
  }, [links, events]);

  useEffect(() => {
    if (shouldRefreshLinksRef.current) {
      handleLinksAndEvents();
      shouldRefreshLinksRef.current = false;
    }
  }, [shouldRefreshLinksRef.current]);

  return (
    <Container>
      <PageContent>
        {campaign.has_links || links.length > 0 ?
          <LinksPageContent
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            totalPages={totalPages}
            setPage={setPage}
            page={page}
            setSort={setSort}
            setSearchInputvalue={setSearchInputvalue}
            handleSearchInputValue={handleSearchInputValue}
            searchInputValue={searchInputValue}
            linksWithMetrics={linksWithMetrics}
            sort={sort}
            handleEditLink={handleEditLink}
            linksType={linksType}
            adsFilter={adsFilter}
            setAdsFilter={setAdsFilter}
            adsFilterOptions={adsFilterOptions}
            campaignID={campaign.id}
          />
          :
          <CampaignsLinksEmptyState campaign={campaign} />
        }

      </PageContent>
      <AnimatePresence>
        {editLinkModalOpen && (
          <EditLinkModal
            handleCloseModal={handleCloseEditLinkModal}
            selectedLink={selectedLinkForEdit}
            isMoldaOpen={editLinkModalOpen}
            setIsModalOpen={setEditLinkModalOpen}
          />
        )}
      </AnimatePresence>
    </Container>
  );
};

export default CampaignLinksContent;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  overflow:hidden;
  h2 {
    width: 100%;
  },
  gap: 0px;
`;
const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  padding-bottom: 30px;
  padding: 0 50px;
  position: relative;
  height: 100%;
  padding-top: 20px;
`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
`;
