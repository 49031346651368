import { useState } from "react";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  FloatingPortal,
} from "@floating-ui/react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";


const TagsTooltip = ({ value }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "top",
    // Make sure the tooltip stays on the screen
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(5),
      flip({
        fallbackAxisSideDirection: "start",
      }),
      shift(),
    ],
  });

  // Event listeners to change the open state
  const hover = useHover(context, { move: false });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  // Role props for screen readers
  const role = useRole(context, { role: "tooltip" });

  // Merge all the interactions into prop getters
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ]);


  return (
    <>
      {value.length > 0 ? (
        <CustomButton ref={refs.setReference} {...getReferenceProps()}>
          <p>
            {value[0]} {value.length > 1 && <span>+{value.length - 1}</span>}
          </p>
        </CustomButton>
      ) : null}
      <FloatingPortal>
        {isOpen && value.length > 1 && (
          <TagsWrapper

            className="Tooltip"
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}

          >
            <h1>Tags</h1>
            <p>{value?.join(", ")}</p>
          </TagsWrapper>
        )}
      </FloatingPortal>
    </>
  );
};

export default TagsTooltip;

const TagsWrapper = styled.div`
  padding: 10px;
  gap: 20px;
  border-bottom: 1px solid ${lightThemeColors.menuBackground};
  display: flex;
  flex-direction: column;
  gap: 10px;
  background: rgba(244, 249, 255, 1);
  border-radius: 5px;
  box-shadow: 0px 0px 20px 0px rgba(151, 168, 190, 0.15);
  max-width: 200px;
z-index:5;
  h1 {
    color: ${lightThemeColors.darkColor};
    opacity: 0.7;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }
  p {
    width: 100%;
    color: ${lightThemeColors.links.tableCellColor};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
  }
`;
const CustomButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    display: flex;
    align-items: center;
    gap: 10px;
    width: fit-content;
    padding: 4px 10px;
    color: ${lightThemeColors.links.tableCellColor};
    background: rgba(42, 134, 255, 0.05);
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    height: fit-content;
  }
  span {
    font-weight: 500;
    opacity: 0.7;
  }
`;
