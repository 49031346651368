import React, { useEffect, useState } from "react";
import styled from "styled-components";
import TestProdHeader from "../../components/Generic/TestProdHeader";
import { useOutletContext } from "react-router-dom";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";
import moment from "moment";

import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg";
import {
  ACTIVE,
  ARCHIVE,
  CAMPAIGNS,
  INACTIVE,
} from "../../constants/OptionsConstants";

import PrimaryButton from "../../components/Generic/PrimaryButton";
import CampaignsOptionsLine from "./CampaignsOptionsLine";
import CampaignsPageContent from "./CampaignsPageContent";

import { useCampaignsContext } from "../../context/useCampaignsContext";
import { AnimatePresence } from "framer-motion";
import CreateNewCampaignModal from "../../components/capmaignsComponents/CreateNewCampaignModal";

import CampaignDetailsModal from "./CampaignDetailsModal";



const CampaignsPage = () => {
  const initialSort = {
    sortKey: "updated_at",
    ascendent: false,
  };

  const [createNewCampaignModalOpen, setCreateNewCampaignModalOpen] =
    useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const { projectType, setProjectType } = useOutletContext();
  const { selectedProject } = useInstanceContext();

  const { campaigns, getCampaigns, campaignsRef, setCampaigns } = useCampaignsContext();
  const { setLoading } = useOutletContext();

  const today = new Date();
  const initialDateStart = moment(today)
    .subtract(29, "day")
    .startOf("day")
    .utc();
  const initialDateEnd = moment(today).endOf("day").utc();
  const [linksType, setLinksType] = useState(ACTIVE);
  const [searchInputValue, setSearchInputvalue] = useState("");
  const [startDate, setStartDate] = useState(new Date(initialDateStart));
  const [endDate, setEndDate] = useState(new Date(initialDateEnd));
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sort, setSort] = useState(initialSort);
  const [archived, setArchived] = useState(false);


  const handleGetCampaigns = () => {
    var dataObject = {
      archived: linksType === INACTIVE,
      ascendent: sort.ascendent,
      page: page,
      start_date: moment(startDate).toISOString(),
      sort_by: sort.sortKey,
    };

    if (searchInputValue != "") {
      dataObject.term = searchInputValue;
    }

    if (endDate) {
      dataObject.end_date = moment(endDate).toISOString();
    }

    setLoading(false);
    setLoading(true);
    getCampaigns(
      selectedProject.id,
      dataObject,
      (response) => {
        setLoading(false);
        campaignsRef.current = response.data;
        setCampaigns(campaignsRef.current);
        setTotalPages(response.data.total_pages);
      },
      (error) => {
        console.log(error);
        showGenericError();
        setLoading(false);
      }
    );
  };

  const handleSearchInputValue = (inputValue) => {
    setSearchInputvalue(inputValue);
  };

  const handleSelectedCampaign = (campaign) => {
    const mappedCampaigns = campaigns?.campaigns?.result?.map((item) => { return item })
    let foundCampaign = mappedCampaigns?.find((item) => item.campaign.id === campaign.id)
    setSelectedCampaign(foundCampaign);
  };

  useEffect(() => {
    if (!selectedProject) {
      return;
    }

    handleGetCampaigns();
  }, [
    selectedProject,
    startDate,
    endDate,
    linksType,
    page,
    searchInputValue,
    sort,
    selectedCampaign,
  ]);


  useEffect(() => {
    setArchived(linksType === INACTIVE);
  }, [linksType]);

  return (
    <Container>
      <TestProdHeader
        title={CAMPAIGNS}
        option={projectType}
        setOption={setProjectType}
        customButton={
          <PrimaryButton
            icon={<PlusIcon />}
            text={"New Campaign"}
            onClick={() => setCreateNewCampaignModalOpen(true)}
          />
        }
      />
      <PageContent>
        <CampaignsOptionsLine
          linksType={linksType}
          setLinksType={setLinksType}
        />
        <CampaignsPageContent
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          totalPages={totalPages}
          setPage={setPage}
          page={page}
          setSort={setSort}
          setSearchInputvalue={setSearchInputvalue}
          handleSearchInputValue={handleSearchInputValue}
          searchInputValue={searchInputValue}
          sort={sort}
          campaigns={campaigns}
          handleSelectedCampaign={handleSelectedCampaign}
          archived={archived}
        />
      </PageContent>

      <AnimatePresence>
        {createNewCampaignModalOpen && (
          <CreateNewCampaignModal
            isMoldaOpen={createNewCampaignModalOpen}
            setIsModalOpen={setCreateNewCampaignModalOpen}
            handleGetCampaigns={handleGetCampaigns}

          />
        )}
      </AnimatePresence>

      <AnimatePresence>
        {selectedCampaign && (
          <CampaignDetailsModal
            campaign={selectedCampaign.campaign}
            handleGetCampaigns={handleGetCampaigns}
            closeModal={() => {
              setSelectedCampaign(null);
            }}
          />
        )}
      </AnimatePresence>
    </Container>
  );
};

export default CampaignsPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;

  h2 {
    width: 100%;
  }
  gap: 0px;
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  padding-bottom: 30px;
  padding: 0 50px;
  position: relative;
  height: 100%;
  padding-top: 20px;
`;
