import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import AddMetrics from "../../components/linksComponents/AddMetrics";
import SelectedMetricTag from "../../components/linksComponents/SlectedMetricTag";
import { useExpanded } from "../../hooks/useExpanded";
import ConfigurationSectionHeader from "../../components/configurationComponents/ConfigurationSectionHeader";
import { useEventContext } from "../../context/useEventContext";
import { showGenericError } from "../../helper/Notifications";
import { useInstanceContext } from "../../context/useInstanceContext";
import moment from "moment";
import LinksOverviewChart from "../linksPage/LinksOverviewChart";
import { useCampaignsContext } from "../../context/useCampaignsContext";

const CampaignOverview = ({ startDate, endDate, settingsView, archived }) => {
  const { campaignsMetricsForOverview } = useCampaignsContext();
  const { selectedProject } = useInstanceContext();
  const [expanded, expand] = useExpanded(false);

  const [selectedMetrics, setSelectedMetrics] = useState([
    {
      value: "view",
      label: "Views",
    },
    {
      value: "open",
      label: "Opens",
    },
    {
      value: "install",
      label: "Installs",
    },
    {
      value: "reinstall",
      label: "Reinstalls",
    },
  ]);

  const [overviewMetrics, setOverviewMetrics] = useState(null);

  const removeMetricsTag = (item) => {
    let filtered = selectedMetrics.filter(
      (metric) => metric.value !== item.value
    );
    setSelectedMetrics(filtered);
  };

  const handleGetEventsForOverview = () => {
    if (!selectedProject || !startDate || !endDate) {
      return;
    }

    let data = {
      start_date: moment(startDate).toISOString(),
      end_date: moment(endDate).toISOString(),
      archived: archived,
      ascendent: true,
    };

    campaignsMetricsForOverview(
      selectedProject.id,
      data,
      (response) => {
        const metrics = response.data;
        setOverviewMetrics(metrics);
      },
      (error) => {
        showGenericError();
      }
    );
  };

  useEffect(() => {
    handleGetEventsForOverview();
  }, [selectedProject, startDate, endDate, archived]);

  return (
    <Container>
      {!settingsView && (
        <ConfigurationSectionHeader
          title={"Overview"}
          handleExpand={expand}
          expanded={expanded}
          headerStyle={{ position: "relative" }}
        />
      )}
      {(expanded || settingsView) && (
        <ContentContainer>
          <Line>
            <AddMetrics
              selectedMetrics={selectedMetrics}
              setSelectedMetrics={setSelectedMetrics}
              forCampaigns={true}
            />

            {selectedMetrics.map((metric, index) => (
              <SelectedMetricTag
                key={index}
                tagName={metric.label}
                handleRemove={() => removeMetricsTag(metric)}
              />
            ))}
          </Line>
          <Line>
            <LinksOverviewChart
              overviewMetrics={overviewMetrics}
              selectedMetrics={selectedMetrics}
            />
          </Line>
        </ContentContainer>
      )}
    </Container>
  );
};

export default CampaignOverview;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 30px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;
`;
