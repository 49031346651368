import {
  createContext,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import {
  archiveCampaignAPICall,
  createNewCampaignAPICall,
  getCampaignMetricsAPICall,
  getCampaignsAPICall,
  updateCampaignAPICall,
} from "../api/campaigns/campaignsService";

export const CampaignsContext = createContext({
  campaigns: [],
  shouldRefreshCampaignsRef: [],
  campaignsRef: [],
  setCampaigns: () => {},
  createNewCampaign: () => {},
  getCampaigns: () => {},
  updateCampaigns: () => {},
  archiveCampaign: () => {},
  campaignsMetricsForOverview: () => {},
});

const CampaignsContextProvider = ({ children }) => {
  const [campaigns, setCampaigns] = useState(undefined);
  const campaignsRef = useRef();
  const shouldRefreshCampaignsRef = useRef();

  const createNewCampaign = useCallback(
    async (projectid, formData, onSuccess, onError) => {
      createNewCampaignAPICall(
        projectid,
        formData,
        function (response) {
          onSuccess(response);
        },
        onError
      );
    }
  );

  const getCampaigns = useCallback(
    async (projectId, data, onSuccess, onError) => {
      getCampaignsAPICall(
        projectId,
        data,
        function (response) {
          onSuccess(response);
        },
        onError
      );
    }
  );

  const updateCampaigns = useCallback(
    async (projectid, campaignId, formData, onSuccess, onError) => {
      updateCampaignAPICall(
        projectid,
        campaignId,
        formData,
        function (response) {
          onSuccess(response);
        },
        onError
      );
    }
  );

  const archiveCampaign = useCallback(
    async (projectId, campaignId, onSuccess, onError) => {
      archiveCampaignAPICall(
        projectId,
        campaignId,
        function (response) {
          onSuccess(response);
        },
        onError
      );
    }
  );

  const campaignsMetricsForOverview = useCallback(
    async (projectId, data, onSuccess, onError) => {
      getCampaignMetricsAPICall(
        projectId,
        data,
        function (response) {
          onSuccess(response);
        },
        onError
      );
    }
  );

  return (
    <CampaignsContext.Provider
      value={{
        campaigns,
        shouldRefreshCampaignsRef,
        campaignsRef,
        setCampaigns,
        createNewCampaign,
        getCampaigns,
        updateCampaigns,
        archiveCampaign,
        campaignsMetricsForOverview,
      }}
    >
      {children}
    </CampaignsContext.Provider>
  );
};

export const useCampaignsContext = () => useContext(CampaignsContext);

export default CampaignsContextProvider;
