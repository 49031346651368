import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/generic/CloseModalIcon.svg";
import { ReactComponent as ArchiveIcon } from "../../assets/icons/generic/removeIconNoColor.svg";
import { motion } from "framer-motion";
import CampaignLinksContent from "./CampaignLinksContent";
import SecondaryButton from "../../components/Generic/SecondaryButton";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg";
import { useOutletContext } from "react-router-dom";
import DeleteConfirmationChild from "../../components/Generic/DeleteConfirmationWithChild";
import { useState } from "react";
import { ARCHIVE_LINK } from "../../constants/OptionsConstants";
import { useCampaignsContext } from "../../context/useCampaignsContext";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";


const CampaignDetailsModal = ({ campaign, closeModal, handleGetCampaigns }) => {

  const { setAddNewLinkForCampaignID, setLoading } = useOutletContext();
  const [archiveConfirmationOpen, setArchiveConfirmationOpen] = useState(false);
  const { archiveCampaign } = useCampaignsContext();
  const { selectedProject } = useInstanceContext();


  const ref = useRef();

  let element = document.getElementById("sidebarId");

  const handleArchiveCampaign = () => {
    setLoading(true);
    archiveCampaign(
      selectedProject.id,
      campaign.id,
      (response) => {
        setLoading(false);
        handleGetCampaigns();
        closeModal();
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };



  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      const modalElement = document.getElementById("new-link-modal");

      if (
        campaign &&
        ref.current &&
        !ref.current.contains(e.target) &&
        (!modalElement || !modalElement.contains(e.target))
      ) {
        closeModal(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [campaign]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        closeModal();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);


  return (
    <FullscreenContainer>
      <ContainerModal
        ref={ref}
        as={motion.div}
        initial={{ translateX: "100%" }}
        animate={{ translateX: "0" }}
        exit={{ translateX: "100%" }}
        transition={{ duration: 0.4 }}
        left={element?.offsetWidth}
      >
        <HeaderWrapper>
          <TitleWrapper>
            <h1>{campaign.name}</h1>
          </TitleWrapper>
          <RightView>
            {!campaign.archived && (
              <ActionsWrapper>
                <DeleteConfirmationChild
                  variant={ARCHIVE_LINK}
                  open={archiveConfirmationOpen}
                  setOpen={setArchiveConfirmationOpen}
                  action={handleArchiveCampaign}
                >
                  <SecondaryButton
                    icon={
                      <ArchiveIcon
                        style={{ color: "rgba(167, 174, 185, 1)" }}
                      />
                    }
                    text={"Archive campaign"}
                    style={{
                      marginLeft: "auto",
                      color: "rgba(167, 174, 185, 1)",
                      borderColor: "rgba(231, 233, 236, 1)",
                    }}
                    onClick={() => {
                      setArchiveConfirmationOpen(true);
                    }}
                  />
                </DeleteConfirmationChild>
                <PrimaryButton
                  icon={<PlusIcon />}
                  text={"New Link"}
                  onClick={() => {
                    setAddNewLinkForCampaignID(campaign.id);
                  }}
                />
              </ActionsWrapper>
            )}

            <CloneBtn onClick={() => closeModal(false)}>
              <CloseModalIcon />
            </CloneBtn>
          </RightView>
        </HeaderWrapper>
        <CampaignLinksContent campaign={campaign} />
      </ContainerModal>
    </FullscreenContainer>
  );
};

export default CampaignDetailsModal;

const RightView = styled.div`
  display: flex;
  align-items: right;
  gap: 30px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: right;
  gap: 30px;
  flex-direction: row;
`;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

const FullscreenContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
`;

const ContainerModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: calc(100% - ${(props) => props.left}px);
  width: 100%;
  height: 100%;
  margin-left: auto;
  padding: 20px 0;
  gap: 20px;

  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 20px;
    font-weight: 600;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;
const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${lightThemeColors.background};
  padding: 20px 50px;
  gap: 30px;
  border-bottom: 1px solid ${lightThemeColors.menuBackground};
`;
const TitleWrapper = styled.div`
  padding: 0 0px;
  h1 {
    font-size: 25px;
    font-weight: 800;
    color: ${lightThemeColors.darkColor};
  }
`;
