import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../../components/configurationComponents/ConfigurationSectionHeader";
import LableWithArrow from "../../components/configurationComponents/LableWithArrow";
import InputWithFixSection from "../../components/configurationComponents/InputWithFixSection";
import { useConfigurationContext } from "../../context/useConfigurationContext";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";
import { ReactComponent as TrackingIcon } from "../../assets/icons/generic/tracking.svg";
import SubcategoryTitleWrapper from "../../components/configurationComponents/SubcategoryTitleWrapper";
import { ReactComponent as SubdomainIcon } from "../../assets/icons/generic/subdomainIcon.svg";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import DeleteConfirmationChild from "../../components/Generic/DeleteConfirmationWithChild";
import { DOMAIN } from "../../constants/OptionsConstants";
import { openInNewTab } from "../../helper/Utils";

const TrackingConfig = () => {
  const { projectDomain, setGoogleTrackingID } = useConfigurationContext();
  const { selectedProject } = useInstanceContext();
  const [expanded, setExpanded] = useState(false);
  const [trackingID, setTrackingID] = useState("");
  const [saveDisabled, setSaveDisabled] = useState(true);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const checkChanges = () => {
    if (!projectDomain) {
      return;
    }
    if (
      projectDomain.google_tracking_id !== trackingID &&
      trackingID !== "" &&
      trackingID.length >= 10
    ) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  };

  const handleSetSubdomain = () => {
    let formData = new FormData();
    formData.append("google_tracking_id", trackingID);
    setGoogleTrackingID(
      selectedProject.id,
      formData,
      () => {},
      () => {
        showGenericError();
      }
    );
  };

  const handleSaveButtonClick = () => {
    handleSetSubdomain();
  };

  useEffect(() => {
    if (!projectDomain) {
      return;
    }
    setTrackingID(projectDomain.google_tracking_id);
  }, [projectDomain]);

  useEffect(() => {
    checkChanges();
  }, [trackingID, projectDomain]);

  const SaveButton = (
    <Line style={{ marginLeft: "auto" }}>
      <PrimaryButton
        text={"Save"}
        bgVariant={lightThemeColors.background}
        styled={{ color: lightThemeColors.primaryColor }}
        onClick={() => handleSaveButtonClick()}
        disabled={saveDisabled}
      />
    </Line>
  );

  return (
    <Container>
      <ConfigurationSectionHeader
        title={"Links Tracking"}
        handleExpand={handleExpand}
        expanded={expanded}
        showValidation
        isValid={trackingID && trackingID.length >= 10}
        customButton={saveDisabled === false && SaveButton}
        icon={<TrackingIcon />}
        unsavedChanges={saveDisabled === false}
      />

      {expanded && (
        <ContentContainer>
          <SubcategoryContent>
            <SubcategoryTitleWrapper
              text="Google Analytics"
              id={"SubdomainConfigId"}
              colorType={"faded-dark"}
            />
            <Line style={{ alignItems: "start" }}>
              <InputFieldWithValidation
                style={{ maxWidth: "540px" }}
                subtitle={
                  "To ensure accurate tracking, please enter the Tracking ID provided by Google Analytics for your property here. This will allow tracking of all links generated within this environment and project."
                }
                placeholder={"Google Tracking ID"}
                inputValue={trackingID}
                fixInputValue={trackingID}
                handleInputChange={(e) =>
                  setTrackingID(e.currentTarget.value.replace(/\s/g, ""))
                }
                valid={trackingID && trackingID.length >= 10}
                label={"Show me how to get the Google Tracking ID"}
                labelAction={() =>
                  openInNewTab(
                    "https://support.google.com/analytics/answer/9539598?hl=en"
                  )
                }
              />
            </Line>
          </SubcategoryContent>
        </ContentContainer>
      )}
    </Container>
  );
};

export default TrackingConfig;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 30px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 30px;
`;

const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 40px;
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;
