import React, { useEffect, useReducer, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as CloseModalIcon } from "../../assets/icons/generic/CloseModalIcon.svg";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import { motion } from "framer-motion";
import { useCampaignsContext } from "../../context/useCampaignsContext";
import { useInstanceContext } from "../../context/useInstanceContext";
import { useOutletContext } from "react-router-dom";
import { showGenericError } from "../../helper/Notifications";

const CreateNewCampaignModal = ({
  isMoldaOpen,
  setIsModalOpen,
  handleGetCampaigns,
}) => {
  const { createNewCampaign } = useCampaignsContext();
  const { setLoading } = useOutletContext();
  const { selectedProject } = useInstanceContext();
  const [campaignName, setCampaignName] = useState("");
  const ref = useRef();

  const handleCreateNewCampaign = () => {
    let dataObject = {
      name: campaignName,
    };

    setLoading(true);
    createNewCampaign(
      selectedProject.id,
      dataObject,
      (response) => {
        setLoading(false);
        handleGetCampaigns();
        setIsModalOpen(false);
      },
      (error) => {
        showGenericError();
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (setIsModalOpen && ref.current && !ref.current.contains(e.target)) {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [setIsModalOpen]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setIsModalOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  return (
    <FullscreenContainer>
      <InviteModal
        ref={ref}
        as={motion.div}
        initial={{ translateX: "100%" }}
        animate={{ translateX: "0" }}
        exit={{ translateX: "100%" }}
        transition={{ duration: 0.4 }}
      >
        <CloneBtn onClick={() => setIsModalOpen(false)}>
          <CloseModalIcon />
        </CloneBtn>
        <HeadWrapper>
          <h1>Create new campaign </h1>
        </HeadWrapper>
        <InfoText>
          A campaign consists of multiple links that can be shared across
          various platforms. By creating a campaign, you can organize all your
          links under a unified marketing objective. Once set up, you’ll be able
          to add links and track performance metrics for each one within the
          campaign.
        </InfoText>
        <ColumnGroup>
          <ColumnGroup style={{ gap: 10 }}>
            <InputFieldWithValidation
              title={"Name"}
              placeholder={"Enter the name of the campaign"}
              noValidation
              inputName={"campaign_name"}
              handleInputChange={(e) => setCampaignName(e.currentTarget.value)}
            />
            <InfoText style={{ marginTop: 0 }}>
              This serves as the identifier for your campaign.{" "}
            </InfoText>
          </ColumnGroup>
          <PrimaryButton
            disabled={campaignName.length < 3}
            styled={{ width: "100%", padding: "12px" }}
            text={"Create Campaign"}
            onClick={() => handleCreateNewCampaign()}
          />
        </ColumnGroup>
      </InviteModal>
    </FullscreenContainer>
  );
};

export default CreateNewCampaignModal;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

const FullscreenContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 5;
`;

const InviteModal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  max-width: 600px;
  width: 100%;
  height: 100%;
  margin-left: auto;
  padding: 40px;
  gap: 20px;

  .control {
    border-radius: 5px;
  }
`;

const CloneBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 30px;
`;
const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  h1 {
    color: ${lightThemeColors.darkColor};
    font-size: 20px;
    font-weight: 600;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;
const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
`;
