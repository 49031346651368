import React from "react";
import { lightThemeColors } from "../../assets/styles/colors";
import styled from "styled-components";

import { copyToClipboard } from "../../helper/copy";
import { useInstanceContext } from "../../context/useInstanceContext";
import SecondaryButton from "../../components/Generic/SecondaryButton";
import { ReactComponent as CopyIcon } from "../../assets/icons/generic/CopyIcon.svg";

const AndroidIntegrationText = ({ ref, onMouseMove }) => {
  const { selectedInstance } = useInstanceContext();

  const indent = "        ";
  return (
    <Container>
      <InfoText>
        In your Application class initialize the SDK in its onCreate() method.
      </InfoText>
      <Line>
        <TextCodeContainer
          id={"androidSDKText"}
          ref={ref}
          onMouseMove={onMouseMove}
        >
          <span
            data-tooltip-id={"androidSDK-tooltip"}
            data-tooltip-content={"Click text to copy"}
            onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
          >{`import io.grovs.Grovs`}</span>
          <br />
          {`class MainApplication : Application() {`}
          <br />
          <br />
          {`      override fun onCreate() {`}
          <br />
          {`            super.onCreate()`}
          <br />
          <br />
          <span
            data-tooltip-id={"androidSDK-tooltip"}
            data-tooltip-content={"Click text to copy"}
            onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
          >
            {" "}
            {`            Grovs.configure(this, "${selectedInstance.api_key}", useTestEnvironment = false)`}
          </span>
          {`    }`}
          <br />
          {`}`}
        </TextCodeContainer>
      </Line>
      <Line>
        <SecondaryButton
          style={{ marginLeft: "auto" }}
          icon={<CopyIcon />}
          text={"Copy"}
          onClick={() =>
            copyToClipboard(document.getElementById("androidSDKText").innerText)
          }
        />
      </Line>
      <InfoText>
        In your launcher activity add the code for handling incoming links.
      </InfoText>
      <Line>
        <TextCodeContainer
          id={"androidSDKText2"}
          ref={ref}
          onMouseMove={onMouseMove}
        >
          <span
            data-tooltip-id={"androidSDK-tooltip"}
            data-tooltip-content={"Click text to copy"}
            onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
          >{`import io.grovs.Grovs`}</span>
          <br />
          {`class MainActivity : ComponentActivity() {`}
          <br />
          <br />
          {`      override fun onStart() {`}
          <br />
          {`            super.onStart()`}
          <br />
          <br />
          <span
            data-tooltip-id={"androidSDK-tooltip"}
            data-tooltip-content={"Click text to copy"}
            onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
          >
            {" "}
            {`          Grovs.onStart()`}
          </span>
          {`    }`}
          <br />
          <br />
          {`      override fun onNewIntent(intent: Intent) {`}
          <br />
          {`          super.onNewIntent(intent)`}
          <br /> <br />
          <span
            data-tooltip-id={"androidSDK-tooltip"}
            data-tooltip-content={"Click text to copy"}
            onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
          >
            {" "}
            {`          Grovs.onNewIntent(intent)`}
          </span>
          {`    }`}
          <br />
          {`}`}
        </TextCodeContainer>
      </Line>
      <Line>
        <SecondaryButton
          style={{ marginLeft: "auto" }}
          icon={<CopyIcon />}
          text={"Copy"}
          onClick={() =>
            copyToClipboard(
              document.getElementById("androidSDKText2").innerText
            )
          }
        />
      </Line>
      <InfoText>
        Register a listener OR collect the flow to receive the link and payload
        from which the app was opened.
      </InfoText>
      <Line>
        <TextCodeContainer
          id={"androidSDKText3"}
          ref={ref}
          onMouseMove={onMouseMove}
        >
          {`// Listen for open from link events using listeners`}
          <span
            data-tooltip-id={"androidSDK-tooltip"}
            data-tooltip-content={"Click text to copy"}
            onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
          >{`Grovs.setOnDeeplinkReceivedListener(this) { link, payload ->
      val message = "Got link from listener: $link payload: $payload"
      Log.d("Grovs", message)
}`}</span>
          <br />
          {`// OR`}
          <br />
          <br />
          {`// Listen for open from link events using kotlin coroutines`}
          <span
            data-tooltip-id={"androidSDK-tooltip"}
            data-tooltip-content={"Click text to copy"}
            onClick={(e) => copyToClipboard(e.currentTarget.innerText)}
          >{`Grovs.Companion::openedLinkDetails.flow.collect { deeplinkDetails ->
    val message = "Got link from flow: \${deeplinkDetails?.link} payload: \${deeplinkDetails?.data}"
    Log.d("Linksquared", message)
}`}</span>
        </TextCodeContainer>
      </Line>
      <Line>
        <SecondaryButton
          style={{ marginLeft: "auto" }}
          icon={<CopyIcon />}
          text={"Copy"}
          onClick={() =>
            copyToClipboard(
              document.getElementById("androidSDKText3").innerText
            )
          }
        />
      </Line>
    </Container>
  );
};

export default AndroidIntegrationText;

const TextCodeContainer = styled.pre`
  display: flex;
  flex-direction: column;
  border: 1px solid ${lightThemeColors.menuBorder};
  border-radius: 5px;
  padding: 16px;
  width: 100%;
  overflow: hidden;
  white-space: pre-wrap;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: ${lightThemeColors.darkColorFaded60};
  h2 {
    white-space: pre-wrap;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: ${lightThemeColors.darkColorFaded60};
  }
  span {
    &:hover {
      background: ${lightThemeColors.sidebar.linkBg};
    }
    white-space: pre-wrap;
    width: fit-content;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    font-weight: 600;
    color: ${lightThemeColors.primaryColor};
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 20px;
`;

const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 40px;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  p {
    color: ${lightThemeColors.darkColor};
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
  }
`;
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
  margin: 30px 0;
`;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  max-width: 635px;
  h3 {
    margin-top: 10px;
    color: ${lightThemeColors.darkColorFaded};
    font-size: 15px;
  }
`;
